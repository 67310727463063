<template>
  <div>
    <div class="fullPageContainer">
      <div class="spindiv" v-if="index > 0">
        <q-spinner-ios style="margin: auto" color="primary" size="2em" />
      </div>
      <table v-else style="width: 100%;">
        <thead>
          <tr class="redt">
            <th>№</th>
            <th>Название</th>
            <th>Дата</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="f in files" :key="f.id">
            <td style="width: 15px">{{ f.id }}</td>
            <td>
              <a
                target="_blank"
                :download="f.name"
                :href="$h.defaults.baseURL +'/'+ f.link"
                >{{ f.name }}
              </a>
            </td>
            <td>
              {{ new Date(f.created_at * 1000).toLocaleDateString("ru-Ru") }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "parser_result",
  props: {
    tab: { type: Number },
    p: { type: Object },
    fName: { type: String }
  },
  data() {
    return {
      files: [],
      index: 0
    };
  },
  watch: {
    index(val) {
      this.$store.state.progress = val == 0 ? false : true;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.index++;
      this.$h.get("/result/get-reports").then(r => {
        this.files = r.data.data;
        this.index--;
      });
    }
  }
};
</script>

<style>
.spindiv {
  display: flex;
  justify-content: center;
  margin: auto;
  height: 300px;
}

.select_item td {
  background-color: #fff4e3 !important;
}

.tableContainer,
.tableSubContainer {
  overflow: auto;
  height: 350px;
  border: 1px solid;
}

.fullPageContainer {
  overflow: auto;
  height: 650px;
  border: 1px solid;
}

.fullPageContainer,
.tableSubContainer,
.tableSubContainer {
  align-content: center;
  width: 100%;
}

.tableContainer table,
.fullPageContainer table,
.tableSubContainer table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  border-color: gray;
  width: 100%;
}

.fullPageContainer table td,
.fullPageContainer table th,
.tableContainer table td,
.tableContainer table th {
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
  min-width: 50px;
  background: #ffffff;
}

.tableContainer table th[scope="row"],
.tableContainer table thead tr {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
}

.fullPageContainer table th[scope="row"],
.fullPageContainer table thead tr {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
}

.tableSubContainer table td,
.tableSubContainer table th {
  text-align: left;
  padding: 4px;
  min-width: 50px;
  background: #ffffff;
}

.tableSubContainer table th[scope="row"],
.tableSubContainer table thead tr {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
}

.res_tr:hover {
  background-color: #e7e7e7;
  cursor: pointer;
}

.res_tr:hover .res_td {
  cursor: pointer;
  background-color: transparent; /* or #000 */
}

.res_td {
  padding: 5px;
}

.b_link_params,
.b_name,
.b_minc {
  /*
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  */
  white-space: nowrap;
  width: 200px;
}

.b_link_params {
  width: 150px !important;
}

.b_sku_name {
  //width: 250px;
  display: block;
}

.b_alg {
  width: 150px;
  display: table-cell;
  text-align: center;
}

.thide .tableSubContainer {
  display: none;
}

.b_marg,
.b_nmarg {
  text-align: right !important;
}

.th_select {
  color: #0f2027;
  cursor: pointer;
  text-align: left;
}

.th_select_s {
  cursor: pointer;
  color: #00bbff;
  text-align: left;
}

.th_select:hover {
  color: gray;
}
</style>
