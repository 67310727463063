<template>
  <div>Not found</div>
</template>

<script>
export default {
  name: "error"
};
</script>

<style scoped></style>
