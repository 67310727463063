<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card>
        <q-tabs
          v-model="tab"
          dense
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
        >
          <q-tab
            name="users"
            label="Пользователи"
            v-if="$store.state.user.role === 'admin'"
          />
          <q-tab name="prices" label="Цены" />
          <!--<q-tab name="cities" label="Регионы" />-->
          <q-tab
            name="directory"
            label="Справочники"
            v-if="$store.state.user.role === 'admin'"
          />
        </q-tabs>

        <q-separator />
        <q-tab-panels v-model="tab" animated style="min-height: 640px">
          <!--
          <q-tab-panel v-if="tab == 'cities'" name="cities">
            <cities />
          </q-tab-panel>-->
          <q-tab-panel v-if="tab == 'users'" name="users">
            <users />
          </q-tab-panel>
          <q-tab-panel v-else-if="tab == 'prices'" name="prices">
            <prices />
          </q-tab-panel>
          <q-tab-panel v-else-if="tab == 'directory'" name="directory">
            <directory />
          </q-tab-panel>
          <q-tab-panel v-else-if="tab == 'parser'" name="parser">
            <parser />
          </q-tab-panel>
        </q-tab-panels>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import Cities from "@/components/settings/tabs/cities.vue";
import Users from "@/components/settings/tabs/users.vue";
import Prices from "@/components/settings/tabs/prices.vue";
import Parser from "@/components/settings/tabs/parser.vue";
import Directory from "@/components/settings/tabs/directory.vue";

export default {
  name: "def_settings",
  components: {
    Directory,
    Parser,
    Prices,
    Users
    //  Cities
  },
  data() {
    return {
      user: localStorage.getItem("user") || null,
      tab: "prices"
    };
  },
  created() {
    this.$router.push({
      path: this.$route.path,
      query: {
        tab: this.tab
      }
    });
  },
  mounted() {},
  watch: {
    tab(val) {
      this.$router.push({
        path: this.$route.path,
        query: {
          tab: val
        }
      });
    }
  },
  methods: {}
};
</script>
