<template>
  <q-page class="ub-page">
    <Prices />
  </q-page>
</template>

<script>
import Prices from "@/components/slave_prices/algorithms.vue";

export default {
  name: "slave_algorithms",
  components: { Prices },
  data: () => {
    return {};
  }
};
</script>

<style scoped></style>
