<template>
  <q-list class="s_bar">
    <div
      v-if="mode === 'expand'"
      :class="!flist ? 'n_menu n_menu_s' : 'n_menu'"
    >
      <menu-list-item
        v-for="item in menuItems"
        v-bind:key="item.id"
        :item="item"
        :collapsed="collapsed"
        :active-class="activeClass"
        class="bvm_menu"
        @expansionItemClick="onExpansionItemClick(item)"
        @itemExpanded="onItemExpanded(item)"
        @itemCollapsed="onItemCollapsed(item)"
      />
    </div>
    <div v-else>
      <menu-list-item-ex
        v-for="item in menuItems"
        v-bind:key="item.id"
        :item="item"
        :collapsed="collapsed"
        :active-class="activeClass"
        class="bvm_menu"
        @expansionItemClick="onExpansionItemClick(item)"
        @itemExpanded="onItemExpanded(item)"
        @itemCollapsed="onItemCollapsed(item)"
      />
    </div>
  </q-list>
</template>
<script>
import MenuListItem from "./menulistitem.vue";
import MenuListItemEx from "./menulistitemex.vue";
export default {
  name: "MenuList",
  components: { MenuListItem, MenuListItemEx },
  props: {
    menuItems: {
      type: Array,
      default: null
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    flist: {
      type: Boolean,
      default: false
    },
    activeClass: {
      type: String,
      default: ""
    },
    mode: {
      type: String,
      default: "expand"
    }
  },
  data: function() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    onExpansionItemClick(item) {
      this.$emit("expansionItemClick", item);
    },
    logout: function() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    onItemExpanded(item) {
      this.$emit("itemExpanded", item);
    },
    onItemCollapsed(item) {
      this.$emit("itemCollapsed", item);
    }
  }
};
</script>
<style lang="scss">
.db-menu-list {
  a,
  a:hover {
    text-decoration: none;
  }
}
</style>
