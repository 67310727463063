<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <div class="q-gutter-md" style="text-align: center">
        <h4 style="text-align: center;font-weight: bold">
          Конкурент
          {{ (comp.name ? comp.name + " " : "") + comp.competitor_id }} -
          алгоритм "{{ alg.name }}"
        </h4>
      </div>
      <q-card style="min-height: 650px">
        <div class="q-pa-md example-column-equal-width">
          <div class="row" style="text-align: center">
            <div class="col-1">Все кроме</div>
          </div>
          <div class="row">
            <div class="col-1" style="text-align: center">
              <q-checkbox v-model="alg.cats_add" />
            </div>
            <div class="col-6">
              <q-select
                filled
                v-model="compCats"
                :options="categories"
                label="Добавить категорию"
                multiple
                emit-value
                map-options
                dense
                use-chips
                input-debounce="0"
              >
                <template
                  v-slot:option="{
                    itemProps,
                    itemEvents,
                    opt,
                    selected,
                    toggleOption
                  }"
                >
                  <q-item v-bind="itemProps" v-on="itemEvents">
                    <q-item-section>
                      <q-item-label v-html="opt.label"></q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-toggle :value="selected" @input="toggleOption(opt)" />
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
            <div class="col-3">
              <q-select
                filled
                v-model="searchCat"
                use-input
                dense
                hide-selected
                input-debounce="0"
                label="Найти категорию"
                :options="optionsCat"
                @filter="filterCat"
                style="width: 250px"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      No results
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
            <div class="col-2">
              <q-btn
                v-if="compCats.length != categories.length"
                @click="selectAllCats"
                >Выбрать все
              </q-btn>
              <q-btn v-else @click="compCats = []">Убрать все</q-btn>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-1" style="text-align: center">
              <q-checkbox v-model="alg.city_add" />
            </div>
            <div class="col-6">
              <q-select
                filled
                v-model="regCity"
                :options="cities"
                label="Добавить город"
                multiple
                emit-value
                map-options
                dense
                use-chips
                input-debounce="0"
              >
                <template
                  v-slot:option="{
                    itemProps,
                    itemEvents,
                    opt,
                    selected,
                    toggleOption
                  }"
                >
                  <q-item v-bind="itemProps" v-on="itemEvents">
                    <q-item-section>
                      <q-item-label v-html="opt.label"></q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-toggle :value="selected" @input="toggleOption(opt)" />
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
            <div class="col-3">
              <q-select
                filled
                v-model="searchCity"
                use-input
                dense
                hide-selected
                input-debounce="0"
                label="Найти город"
                :options="optionsCity"
                @filter="filterCity"
                style="width: 250px"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      No results
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
            <div class="col-2">
              <q-btn
                v-if="regCity.length < cities.length"
                @click="selectAllCities"
                >Выбрать все
              </q-btn>
              <q-btn v-else @click="regCity = []">Убрать все</q-btn>
            </div>
          </div>-->
          <div class="row">
            <div class="col-1" style="text-align: center">
              <q-checkbox v-model="alg.brand_add" />
            </div>
            <div class="col-6">
              <q-select
                filled
                v-model="compBrands"
                :options="brands"
                label="Добавить Бренд"
                multiple
                emit-value
                map-options
                dense
                use-chips
                input-debounce="0"
              >
                <template
                  v-slot:option="{
                    itemProps,
                    itemEvents,
                    opt,
                    selected,
                    toggleOption
                  }"
                >
                  <q-item v-bind="itemProps" v-on="itemEvents">
                    <q-item-section>
                      <q-item-label v-html="opt.label"></q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-toggle :value="selected" @input="toggleOption(opt)" />
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
            <div class="col-3">
              <q-select
                filled
                v-model="searchBrand"
                use-input
                dense
                hide-selected
                input-debounce="0"
                label="Найти бренд"
                :options="optionsBrands"
                @filter="filterCity"
                style="width: 250px"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      No results
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
            <div class="col-2">
              <q-btn
                v-if="compBrands.length != brands.length"
                @click="selectAllBrands"
                >Выбрать все
              </q-btn>
              <q-btn v-else @click="compBrands = []">Убрать все</q-btn>
            </div>
          </div>
          <div class="row">
            <div class="col-1" style="text-align: center">
              <q-checkbox v-model="alg.price_add" />
            </div>
            <div class="col-6">
              <q-select
                filled
                v-model="compPrices"
                :options="prices"
                label="Добавить цену"
                multiple
                emit-value
                map-options
                dense
                use-chips
                input-debounce="0"
              >
                <template
                  v-slot:option="{
                    itemProps,
                    itemEvents,
                    opt,
                    selected,
                    toggleOption
                  }"
                >
                  <q-item v-bind="itemProps" v-on="itemEvents">
                    <q-item-section>
                      <q-item-label v-html="opt.label"></q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-toggle :value="selected" @input="toggleOption(opt)" />
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
            <div class="col-3">
              <q-select
                filled
                v-model="searchPrice"
                use-input
                dense
                hide-selected
                input-debounce="0"
                label="Найти цену"
                :options="optionsPrices"
                @filter="filterPrices"
                style="width: 250px"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      No results
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
            <div class="col-2">
              <q-btn
                v-if="compPrices.length < prices.length"
                @click="selectAllPrices"
                >Выбрать все
              </q-btn>
              <q-btn v-else @click="compPrices = []">Убрать все</q-btn>
            </div>
          </div>
          <div class="row" v-if="index == 0">
            <div class="col-12">
              <formula
                :formulas.sync="formula"
                :arr-types="items"
                @formulaUpdate="upFormula"
              />
            </div>
          </div>
          <div
            class="row"
            style="text-align: center;padding: 15px 0;display: block"
          >
            <q-btn color="blue" align="right" @click="saveComp"
              >Сохранить</q-btn
            >
          </div>
        </div>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import { Notify } from "quasar";
import Formula from "@/components/settings/competitor/formula.vue";

export default {
  name: "edit_competitors",
  components: { Formula },
  data() {
    return {
      searchCat: null,
      searchCity: null,
      searchPrice: null,
      searchBrand: null,
      optionsCat: [],
      optionsCatList: [],
      optionsCity: [],
      optionsCityList: [],
      optionsBrands: [],
      optionsBrandsList: [],
      optionsPrices: [],
      optionsPricesList: [],
      prices: [],
      items: {},
      comp: {
        name: "",
        competitor_id: 0
      },
      alg: {
        name: "",
        id: 0
      },
      id: null,
      alg_id: null,
      regCity: [],
      compCats: [],
      compBrands: [],
      cityCompet: [],
      compPrices: [],
      categories: [],
      cities: [],
      brands: [],
      index: 0,
      formula: [
        {
          condition: null,
          formula: { calc: [], cond: [] }
        }
      ]
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.alg_id = this.$route.query.alg_id;
    if (this.id) {
      this.index++;
      this.$h
        .get(`/competitors/get-info-alg?id=${this.id}&alg=${this.alg_id}`)
        .then(r => {
          this.comp = r.data.data.comp;
          this.alg = r.data.data.alg;
        })
        .then(() => {
          this.getItems();
          this.index--;
        });
    }
  },
  mounted() {},
  watch: {
    index(val) {
      this.$store.state.progress = val == 0 ? false : true;
    },
    searchCat(val) {
      for (let c in this.categories) {
        if (this.categories[c].label === val) {
          if (this.compCats.indexOf(this.categories[c].value) == -1) {
            this.compCats.push(this.categories[c].value);
          }
        }
      }
    },
    searchPrice(val) {
      for (let c in this.prices) {
        if (this.prices[c].label === val) {
          if (this.compPrices.indexOf(this.prices[c].value) == -1) {
            this.compPrices.push(this.prices[c].value);
          }
        }
      }
    },
    searchBrand(val) {
      for (let c in this.brands) {
        if (this.brands[c].label === val) {
          if (this.compBrands.indexOf(this.brands[c].value) == -1) {
            this.compBrands.push(this.brands[c].value);
          }
        }
      }
    },
    searchCity(val) {
      for (let c in this.cities) {
        if (this.cities[c].label === val) {
          if (this.regCity.indexOf(this.cities[c].value) == -1) {
            this.regCity.push(this.cities[c].value);
          }
        }
      }
    }
  },
  methods: {
    getItems() {
      this.index++;
      this.$h.get(`/competitors/get-items?id=${this.alg_id}`).then(r => {
        this.items = r.data.data.items;
        const prices = r.data.data.prices;

        for (let c in prices) {
          this.optionsPricesList.push(prices[c].name);
          this.optionsPrices.push(prices[c].name);
          this.prices.push({
            label: prices[c].name,
            value: prices[c].price_id
          });
        }
        const cats = r.data.data.cats;

        for (let c in cats) {
          this.optionsCatList.push(cats[c].name);
          this.optionsCat.push(cats[c].name);
          this.categories.push({
            label: cats[c].name,
            value: cats[c].id
          });
        }
        const cits = r.data.data.cities;
        for (let c in cits) {
          this.optionsCityList.push(cits[c].name);
          this.optionsCity.push(cits[c].name);
          this.cities.push({
            label: cits[c].name,
            value: cits[c].city_id
          });
        }

        const brands = r.data.data.brands;
        for (let c in brands) {
          this.optionsBrandsList.push(brands[c].name);
          this.optionsBrands.push(brands[c].name);
          this.brands.push({
            label: brands[c].name,
            value: brands[c].brand_id
          });
        }

        const set = r.data.data.setting;
        this.regCity = set.cities || [];
        this.compCats = set.cats || [];
        this.compPrices = set.prices || [];
        this.compBrands = set.brands || [];

        const fomula = r.data.data.formula;
        if (fomula) {
          this.formula = fomula;
        }
        this.index--;
      });
    },
    filterCat(val, update) {
      if (val === "") {
        update(() => {
          this.optionsCat = this.optionsCatList;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.optionsCat = this.optionsCatList.filter(
          v => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    filterBrands(val, update) {
      if (val === "") {
        update(() => {
          this.optionsBrands = this.optionsBrandsList;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.optionsBrands = this.optionsBrandsList.filter(
          v => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    filterCity(val, update) {
      if (val === "") {
        update(() => {
          this.optionsCity = this.optionsCityList;

          // with Quasar v1.7.4+
          // here you have access to "ref" which
          // is the Vue reference of the QSelect
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.optionsCity = this.optionsCityList.filter(
          v => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    filterPrices(val, update) {
      if (val === "") {
        update(() => {
          this.optionsPrices = this.optionsPricesList;

          // with Quasar v1.7.4+
          // here you have access to "ref" which
          // is the Vue reference of the QSelect
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.optionsPrices = this.optionsPricesList.filter(
          v => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    selectAllCities() {
      for (let c in this.cities) {
        let citId = this.cities[c].value;
        if (this.regCity.indexOf(citId) == -1) {
          this.regCity.push(citId);
        }
      }
    },
    selectAllPrices() {
      for (let c in this.prices) {
        let citId = this.prices[c].value;
        if (this.compPrices.indexOf(citId) == -1) {
          this.compPrices.push(citId);
        }
      }
    },
    selectAllCats() {
      for (let c in this.categories) {
        let citId = this.categories[c].value;
        if (this.compCats.indexOf(citId) == -1) {
          this.compCats.push(citId);
        }
      }
    },
    selectAllBrands() {
      for (let c in this.brands) {
        let citId = this.brands[c].value;
        if (this.compBrands.indexOf(citId) == -1) {
          this.compBrands.push(citId);
        }
      }
    },
    upFormula(val) {
      this.formula = val.form;
    },
    saveComp() {
      if (this.compPrices.length == 0) {
        Notify.create({
          position: "top",
          type: "errors",
          color: "red",
          message: "Не выбраана цена",
          timeout: 2000
        });
        return;
      }
      this.index++;
      this.$h
        .post(`/competitors/update-info?id=${this.alg_id}`, {
          alg: this.alg,
          cats: this.compCats,
          prices: this.compPrices,
          cities: this.regCity,
          brands: this.compBrands,
          formula: this.formula
        })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: r.data.message,
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
          }
          this.index--;
        });
    }
  }
};
</script>
