<template>
  <div>
    <div
      v-for="c in prices"
      :key="c.price_id"
      class="row"
      style="padding: 10px 5px"
    >
      <div class="col-2" style="text-align: center">{{ c.price_id }}</div>
      <div class="col-2">
        <b>{{ c.name }}</b>
      </div>
      <div v-if="c.enable" class="col-2">
        <span style="color: green">{{ "Включена" }}</span>
      </div>
      <div v-else class="col-2">
        <span style="color: red">{{ "Выключена" }}</span>
      </div>
      <div class="col-2">
        <q-btn @click="setprices(c)">{{
          c.enable ? "Выключить" : "Включить"
        }}</q-btn>
      </div>
      <div class="col-2">
        <q-checkbox @input="setDiap(c)" v-model="c.diap" label="Диапазоны" />
      </div>
      <div class="col-2" style="display: flex">
        <select
          @change="setDiap(c)"
          v-model="c.comp_id"
          style="margin: auto; width: 100%;text-align: center"
        >
          <option :value="0"></option>
          <option
            v-for="cp in comp"
            :key="cp.competitor_id"
            :value="cp.competitor_id"
            >{{ cp.name }}</option
          >
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "prices",
  components: {},
  data() {
    return {
      prices: {},
      comp: []
    };
  },
  created() {},
  mounted() {
    this.getprices();
  },
  watch: {},
  methods: {
    getprices() {
      this.$store.state.progress = true;
      this.$h.get("/settings/get-prices").then(r => {
        this.prices = r.data.data.prices;
        this.comp = r.data.data.comp;
        this.$store.state.progress = false;
      });
    },
    setprices(comp) {
      comp.enable = !comp.enable;
      this.$store.state.progress = true;
      this.$h.post("/settings/set-prices", { c: comp }).then(r => {
        if (r.data.error) {
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: "Ошибка",
            timeout: 2000
          });
        } else {
          Notify.create({
            position: "top",
            color: "green",
            message: "Сохранено",
            timeout: 2000
          });
          this.prices = r.data.data.prices;
        }
        this.$store.state.progress = false;
      });
    },
    setDiap(comp) {
      this.$store.state.progress = true;
      this.$h.post("/settings/set-prices", { c: comp }).then(r => {
        if (r.data.error) {
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: "Ошибка",
            timeout: 2000
          });
        } else {
          Notify.create({
            position: "top",
            color: "green",
            message: "Сохранено",
            timeout: 2000
          });
          this.prices = r.data.data.prices;
        }
        this.$store.state.progress = false;
      });
    }
  }
};
</script>

<style scoped></style>
