<template>
  <q-layout view="hHh LpR fFf">
    <q-btn
      dense
      flat
      round
      icon="menu"
      @click="leftShown = !leftShown"
      style="float: right; margin-right: 15px; margin-top: 15px"
    />
    <menu-drawer
      v-model="leftShown"
      :mini.sync="menuMini"
      :auto-expand="menuAutoExpand"
    >
      <template v-slot:menu>
        <menu-list
          :flist.sync="menuMini"
          :menu-items="menuItems"
          v-on:expansionItemClick="menuMini = false"
        ></menu-list>
      </template>
    </menu-drawer>

    <q-page-container>
      <q-banner
        v-if="parserStatus"
        dense
        inline-actions
        class="text-white text-body1 bg-red"
        style="text-align: center;font-weight: bold;font-size: 22px"
      >
        <b style="font-size: 18px"
          >{{ statusText }}, Данные могут быть не точными !!!
          <q-spinner-clock color="white" size="18px"
        /></b>
      </q-banner>
      <div>
      <label style="padding: 15px"
      ><h4>{{ $t("menu")[$route.name] }}</h4>
        <q-file style="width: 300px;" :disable="sending" multiple v-model="file" label="Загрузить файл для парсинга" />
        <q-btn v-if="file" @click="submitFile">Отправить</q-btn>
        </label>
      </div>

      <transition :name="transitionName">
        <router-view
          :style="!menuMini ? 'padding: 0 40px 0 0' : 'padding: 0 40px 0 40px'"
        />
      </transition>
    </q-page-container>
    <q-inner-loading
      :showing="$store.state.progress"
      label="Please wait..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    >
      <q-spinner-gears size="50px" :color="$store.state.color" />
      <label
        class="dinamicAlertText"
        style="
          padding: 15px;
          font-size: 16px;
          max-width: 400px;
          text-align: center;
        "
        >{{ $t("pleaseWait")[cNewMessage] }}
      </label>
    </q-inner-loading>
  </q-layout>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { dbColors } from "dashblocks";
import MenuDrawer from "../components/menu/menudrawer.vue";
import MenuList from "../components/menu/menulist.vue";
import {Notify} from "quasar";

export default {
  name: "MainLayout",
  components: {
    MenuDrawer,
    MenuList
  },
  data() {
    return {
      sending:false,
      file:null,
      cNewMessage: 0,
      leftShown: true,
      rightShown: false,
      settingsOpen: false,
      messagesOpen: false,
      userInfoOpen: false,
      showSearchToolbar: false,
      searchQuery: "",
      transitionName: "",
      testColors: null,
      menuItems: [],
      prevPage: "/",
      statusText: "",
      parserStatus: false,
      refreshIntervalId: null
    };
  },
  computed: {
    ...mapState({
      menuAutoExpand: state => state.layout.menuAutoExpand
    }),
    dark: {
      get() {
        return this.$store.state.layout.dark;
      },
      set(value) {
        this.setDark({ dark: value });
      }
    },
    menuMini: {
      get() {
        return this.$store.state.layout.menuMini;
      },
      set(value) {
        this.setMenuMini({ menuMini: value });
      }
    },
    smallScreen() {
      return ["xs", "sm"].includes(this.$q.screen.name);
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  watch: {
    parserStatus(val) {
      if (val) {
        this.refreshIntervalId = setInterval(() => {
          this.getStatus();
        }, 5000);
      } else {
        clearInterval(this.refreshIntervalId);
      }
    },
    dark: {
      handler: function(val) {
        this.$q.dark.set(val);
      }
    },
    "$route.fullPath"(val) {
      console.log(val)
      if (val == "/logout") {
        this.logout();
      }
      this.$store.state.subTitle = null;
      this.$history.push(val);
      if (this.$history.length > 1) {
        this.prevPage = this.$history[this.$history.length - 2];
      } else {
        this.prevPage = "/";
      }
      this.getStatus();
    },
    "$store.state.progress"(val) {
      if (val) {
        this.sendAlertText();
      }
    }
  },
  mounted() {
    this.makeMenu();
    this.initialize();
    this.$q.dark.set(this.dark);
  },
  methods: {
    ...mapActions({
      setDark: "layout/setDark",
      setMenuMini: "layout/setMenuMini"
    }),
    logout: function() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    prevPages() {
      this.$history.splice(this.$history.length - 2, 1);
      if (this.$route.fullPath == this.prevPage) {
        this.$router.push("/");
      }
      this.$router.push(this.prevPage);
    },
    getStatus() {
      this.$h.get("/parser/get-status").then(r => {
        this.statusText = r.data.message;
        this.parserStatus = r.data.error;
      });
    },
    sendAlertText() {
      let l = 0;
      let allerArr = this.$t("pleaseWait");
      setInterval(() => {
        if (l < allerArr.length) {
          // alert[0].innerText = allerArr[l];
          l++;
          this.cNewMessage = l;
        }
      }, 3000);
    },
    makeMenu() {
      this.menuItems.push({
        id: "main_page",
        title: this.$t("menu").main,
        link: "/",
        icon: "mdi-account-settings",
        level: 0
      });
      this.menuItems.push({
        id: "competitors",
        title: this.$t("menu").competitors,
        link: "/competitors",
        icon: "mdi-account-switch",
        level: 0
      });
      this.menuItems.push({
        id: "algorithms",
        title: this.$t("menu").algorithms,
        link: "/algorithms",
        icon: "mdi-repeat",
        level: 0
      });
      this.menuItems.push({
        id: "adjustments",
        title: this.$t("menu").adjustments,
        link: "/adjustments",
        icon: "mdi-repeat-once",
        level: 0
      });
      this.menuItems.push({
        id: "slave_algorithms",
        title: this.$t("menu").slave_algorithms,
        link: "/slave_algorithms",
        icon: "mdi-repeat",
        level: 0
      });
      this.menuItems.push({
        id: "parser_result",
        title: this.$t("menu").parser_result,
        link: "/parser_result",
        icon: "mdi-nfc-tap",
        level: 0
      });

      this.menuItems.push({
        id: "slave_result",
        title: this.$t("menu").slave_result,
        link: "/slave_result",
        icon: "mdi-nfc-tap",
        level: 0
      });
      this.menuItems.push({
        id: "no_comp",
        title: this.$t("menu").no_comp,
        link: "/no-comp",
        icon: "mdi-alert-octagon",
        level: 0
      });

      this.menuItems.push({
        id: "mistakes",
        title: this.$t("menu").mistakes,
        link: "/mistakes",
        icon: "mdi-alert-octagon",
        level: 0
      });
      this.menuItems.push({
        id: "result",
        title: this.$t("menu").result,
        link: "/result",
        icon: "mdi-message-text-outline",
        level: 0
      });
      this.menuItems.push({
        id: "ranges",
        title: this.$t("menu").ranges,
        link: "/ranges",
        icon: "mdi-twitter-retweet",
        level: 0
      });
      /*
      this.menuItems.push({
        id: "analysis",
        title: this.$t("menu").analysis,
        link: "/analysis",
        icon: "mdi-swap-horizontal",
        level: 0
      });
*/
      this.menuItems.push({
        id: "settings",
        title: this.$t("menu").settings,
        link: "/settings",
        icon: "mdi-cog-outline",
        level: 0
      });
      this.menuItems.push({
        id: "logout",
        title: this.$t("menu").logout,
        link: "/logout",
        icon: "mdi-exit-run",
        level: 0
      });
    },
    submitFile() {
      this.sending = true;
      let formData = new FormData();
      for(let t in this.file) {
        formData.append('files[]', this.file[t]);
      }

      this.$h
          .post('/parser/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((r) => {
            if (r.data.error) {
              Notify.create({
                position: "top",
                type: "errors",
                color: "red",
                message: r.data.message,
                timeout: 2000
              });
            } else {
              Notify.create({
                position: "top",
                color: "green",
                message: "Файлы сохранены и скоро будет обработаны",
                timeout: 2000
              });
              this.diapasons = r.data.data;
            }
            this.sending = false;
            this.file = null;
          });
    },
    openFile() {
      window.document.getElementById('file').click();
    },
    initialize() {
      this.getStatus();
      let dbc = dbColors;

      //this.testColors = dbColors.getColors(true); // TEMP TODO REMOVE
      let cSteps = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];

      dbColors.setColorScheme("default", {
        light: dbColors.d3ScaleChromatic.schemeTableau10,
        dark: dbColors.grafanaColors
      });

      dbColors.setColorScheme("Grafana", {
        light: dbColors.grafanaColors,
        dark: dbColors.grafanaColors
      });

      dbColors.setColorScheme("Tableau", {
        light: dbColors.d3ScaleChromatic.schemeTableau10,
        dark: dbColors.d3ScaleChromatic.schemeTableau10
      });

      dbColors.setColorScheme("Diverging", {
        light: dbColors.d3ScaleChromatic.schemeRdYlBu[10],
        dark: dbColors.d3ScaleChromatic.schemeRdYlBu[10]
      });

      dbColors.setColorScheme("Categorical", {
        light: dbColors.d3ScaleChromatic.schemeDark2,
        dark: dbColors.d3ScaleChromatic.schemeSet3 // schemeBuGn[9],
      });

      dbColors.setColorScheme("Warm", {
        light: cSteps.map(x => dbColors.d3ScaleChromatic.interpolateWarm(x)),
        dark: cSteps.map(x => dbColors.d3ScaleChromatic.interpolateWarm(x))
      });

      dbColors.setColorScheme("Cool", {
        light: cSteps.map(x => dbColors.d3ScaleChromatic.interpolateCool(x)),
        dark: cSteps.map(x => dbColors.d3ScaleChromatic.interpolateCool(x))
      });

      dbColors.setColorScheme("Calm", {
        light: [
          "#912e4d",
          "#00bd56",
          "#f02192",
          "#acd36d",
          "#8079ff",
          "#919200",
          "#f1adff",
          "#547600",
          "#ff8241",
          "#f8ba7a"
        ],
        dark: [
          "#ce4c3a",
          "#60b14d",
          "#8162cb",
          "#bab141",
          "#c964b5",
          "#4bb092",
          "#c25874",
          "#717e37",
          "#688ccd",
          "#c78344"
        ]
      });
      //['#cc4ba2', '#64ac48', '#8361cd', '#9a963f', '#5f8cce', '#cd5136', '#4aac8d', '#c7596d', '#c78543', '#b578b6']
      //['#ce4c3a', '#60b14d', '#8162cb', '#bab141', '#c964b5', '#4bb092', '#c25874', '#717e37', '#688ccd', '#c78344']

      dbColors.setColorScheme("Fancy", {
        light: [
          "#38646f",
          "#4e2300",
          "#274f8e",
          "#6b5e1f",
          "#794f81",
          "#2a2e00",
          "#00485e",
          "#7c553f",
          "#2e0a06",
          "#2b2219"
        ],
        dark: [
          "#b1d8a0",
          "#74aff3",
          "#dbcd9d",
          "#7bcaed",
          "#ebaba7",
          "#74d6e0",
          "#deb1e0",
          "#a1e9d1",
          "#adbce9",
          "#8dc4af"
        ]
      });

      dbColors.setColorScheme("Colorblind Friendly", {
        light: [
          "#37efab",
          "#58006c",
          "#b3e370",
          "#9a73ec",
          "#b1a200",
          "#0051ab",
          "#ff9e6a",
          "#601016",
          "#685d00",
          "#de3357"
        ],
        dark: [
          "#78a563",
          "#666fe8",
          "#c1b01b",
          "#014ca6",
          "#ffca5e",
          "#e2b1ff",
          "#008418",
          "#ff77bf",
          "#811e00",
          "#ff8c56"
        ]
      });
    }
  }
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
}
</style>
