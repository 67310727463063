<template>
  <div>
    <q-splitter v-model="splitterModel">
      <template v-slot:before>
        <q-tabs v-model="dir" vertical class="text-teal">
          <q-tab
            v-for="d in directories"
            :name="d.id"
            :label="d.label"
            :key="d.id"
          />
        </q-tabs>
      </template>
      <template v-slot:after>
        <q-tab-panels
          v-model="dir"
          swipeable
          vertical
          transition-prev="jump-up"
          transition-next="jump-up"
          :draggable="false"
        >
          <q-tab-panel
            v-for="d in directories"
            :name="d.id"
            :label="d.label"
            :key="d.id + Math.random()"
          >
            <div class="row" v-for="i in items" :key="i.id">
              <div class="col-1"></div>
              <div class="col-3">
                <q-input outlined :dense="true" v-model="i.name" />
              </div>
              <div class="col-3"></div>
              <div class="col-3">
                <q-btn @click="updateItems(i)">Сохранить</q-btn>
              </div>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </template>
    </q-splitter>
  </div>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "directory",
  components: {},
  data() {
    return {
      splitterModel: 10,
      directories: [
        { id: "cats", label: "Кат 1С" },
        { id: "cats1c", label: "Кат" },
        { id: "prodj", label: "Проект" },
        { id: "status", label: "Статус ПИТ" }
      ],
      item: "",
      name: "",
      schema: [],
      items: [],
      dir: "cats"
    };
  },
  created() {
    this.splitterModel = this.directories.length;
  },
  mounted() {
    if (this.$route.query.dir) {
      this.dir = this.$route.query.dir;
    }
    this.getItems();
  },
  watch: {
    dir() {
      this.getItems();
    }
  },
  methods: {
    getItems() {
      this.$store.state.progress = true;
      this.$h.get(`/settings/get-${this.dir}`).then(r => {
        this.items = r.data.data;
        this.$store.state.progress = false;
      });
    },
    updateItems(item) {
      this.$store.state.progress = true;
      this.$h
        .post(`/settings/set-${this.dir}`, { cId: item.id, name: item.name })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: "Ошибка",
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
          }
          this.$store.state.progress = false;
        });
    }
  }
};
</script>

<style scoped></style>
