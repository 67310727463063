<template>
  <q-page class="ub-page">
    <Def_settings />
  </q-page>
</template>

<script>
import Def_settings from "@/components/settings/def_settings.vue";

export default {
  name: "settings",
  components: { Def_settings },
  data: () => {
    return {};
  }
};
</script>

<style scoped></style>
