<template>
  <q-page class="ub-page">
    <div class="q-gutter-md" style="text-align: center">
      <h4 style="text-align: center;font-weight: bold">
        Конкурент "{{
          (comp.name ? comp.name + " " : "") + comp.competitor_id
        }}"
      </h4>
    </div>
    <div class="q-gutter-y-md" style="max-width: 100%;padding-top: 15px">
      <q-card style="padding: 5px;height: 100%;min-height: 650px">
        <div class="row" style="padding: 25px">
          <div class="col-1"></div>
          <div class="col-2">
            <q-input
              outlined
              label="Новый Алгоритм"
              v-model="algName"
              :dense="true"
            />
          </div>
          <div class="col-1"></div>
          <div class="col-1">
            <q-btn :disable="algName.length < 3" @click="addAlgorithm(cid)"
              >Добавить
            </q-btn>
          </div>
          <div class="col-1"></div>
          <div class="col-2">
            <q-checkbox v-model="comp.main" label="Мы поставщик" />
          </div>
          <div class="col-4">
            <q-btn size="xs" color="blue" @click="setCompetitors(comp)"
              >Обновить</q-btn
            >
          </div>
        </div>
        <div class="row">
          <div class="col-1" style="text-align: center">
            <b>Приоритет</b>
          </div>
          <div class="col-1">ВКЛ/ВЫКЛ</div>
          <div class="col-3" style="text-align: center">
            <b>Название</b>
          </div>
          <div class="col-1" style="text-align: center">Цены</div>
          <div class="col-1" style="text-align: center">Бренды</div>

          <div class="col-1"></div>
          <div class="col-1"></div>
        </div>
        <div
          style="padding-top: 15px"
          class="row"
          v-for="alg in algorithms"
          :key="alg.id + 'a'"
        >
          <div class="col-1" style="text-align: center">
            <select v-model="alg.priority">
              <option v-for="p in priorities" :key="p + 'p'">{{ p }} </option>
            </select>
          </div>
          <div class="col-1"><q-checkbox v-model="alg.alg_enable" /></div>
          <div class="col-3" style="text-align: center">
            <b><input style="width: 100%" v-model="alg.name"/></b>
          </div>
          <div class="col-1" style="text-align: center">{{ alg.prices }}</div>
          <div class="col-1" style="text-align: center">{{ alg.brands }}</div>
          <div class="col-1" style="margin: 0 10px" v-if="alg.reload == false">
            <q-btn
              @click="
                $router.push(
                  `/competitor/algorithms/edit?alg_id=${alg.id}&id=${cid}`
                )
              "
              >Настроить
            </q-btn>
          </div>
          <div class="col-1" style="margin: 0 10px" v-if="alg.reload == false">
            <q-btn color="blue" @click="updateAlg(alg)">Сохранить</q-btn>
          </div>
          <div class="col-1" style="margin: 0 10px" v-if="alg.reload == false">
            <q-btn color="red" @click="deleteDial(alg)">Удалить</q-btn>
          </div>
          <div class="col-1" style="margin: 0 10px" v-if="alg.reload == false">
            <q-btn color="green" @click="reloadAlg(alg)">Пересчет</q-btn>
          </div>
          <div
            class="col-4"
            style="margin: 0 10px;text-align: center"
            v-if="alg.reload == true"
          >
            <q-btn color="green" disable>На пересчете</q-btn>
          </div>
        </div>
      </q-card>
    </div>
    <q-dialog
      v-model="delConfirm"
      v-if="delAlg"
      persistent
      style="min-width: 300px"
    >
      <q-card>
        <q-card-section class="row items-center">
          <span style="text-align: center"
            >Уверены, что хотите удалить <br /><b>{{ delAlg.name }}</b></span
          >
        </q-card-section>

        <q-card-actions align="center">
          <q-btn
            flat
            label="Отмена"
            @click="delConfirm = false"
            color="primary"
            v-close-popup
          />
          <q-btn
            flat
            label="Удалить"
            @click="deleteAlg"
            color="red"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "algorithms",
  components: {},
  data() {
    return {
      delConfirm: false,
      cid: 0,
      comp: {
        name: "",
        competitor_id: 0,
        main: false
      },
      algName: "",
      algorithms: [],
      delAlg: null,
      index: 0
    };
  },
  created() {
    this.cid = this.$route.query.cid || 0;
    if (this.cid) {
      this.index++;
      this.$h.get(`/competitors/get-info?id=${this.cid}`).then(r => {
        this.comp = r.data.data;
        this.comp.main = parseInt(r.data.data.main) == 1 ? true : false;
        this.index--;
      });
    }
  },
  mounted() {
    this.getAlgorithms();
  },
  watch: {
    index(val) {
      this.$store.state.progress = val == 0 ? false : true;
    }
  },
  methods: {
    setCompetitors(comp) {
      const nComp = [];
      nComp.push(comp);
      this.$store.state.progress = true;
      this.$h.post(`/competitors/set-competitor`, { c: nComp }).then(r => {
        if (r.data.error) {
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: "Ошибка",
            timeout: 2000
          });
        } else {
          Notify.create({
            position: "top",
            color: "green",
            message: "Сохранено",
            timeout: 2000
          });
        }
        this.$store.state.progress = false;
      });
    },
    deleteDial(alg) {
      this.delConfirm = true;
      this.delAlg = alg;
    },
    deleteAlg() {
      this.index++;
      console.log(this.delAlg);
      this.$h
        .post(`/competitors/del-alg?cid=${this.cid}`, { alg: this.delAlg })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: r.data.message,
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "red",
              message: "Удалён",
              timeout: 2000
            });
          }
          this.algorithms = r.data.data;
          for (let p in this.algorithms) {
            this.priorities.push(this.algorithms[p].priority);
          }
          this.index--;
        });
    },
    getAlgorithms() {
      this.index++;
      this.priorities = [];
      this.$h.get(`/competitors/get-alg-list?cid=${this.cid}`).then(r => {
        this.algorithms = r.data.data;
        this.algorithms.forEach(alg => {
          this.$watch(() => [alg.alg_enable, alg.id], this.enableAlg, {
            deep: true
          });
        });
        for (let p in this.algorithms) {
          this.priorities.push(this.algorithms[p].priority);
        }
        this.index--;
      });
    },
    addAlgorithm() {
      this.index++;
      this.priorities = [];
      this.$h
        .post("/competitors/add-alg", { name: this.algName, cid: this.cid })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: r.data.message,
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
            this.algorithms = r.data.data;
            for (let p in this.algorithms) {
              this.priorities.push(this.algorithms[p].priority);
            }
          }
          this.index--;
        });
    },
    updateAlg(alg) {
      this.index++;
      this.priorities = [];
      this.$h.post("/competitors/update-alg-info", { alg: alg }).then(r => {
        if (r.data.error) {
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: r.data.message,
            timeout: 2000
          });
        } else {
          Notify.create({
            position: "top",
            color: "green",
            message: "Сохранено",
            timeout: 2000
          });
          this.algorithms = r.data.data;
          for (let p in this.algorithms) {
            this.priorities.push(this.algorithms[p].priority);
          }
        }
        this.index--;
      });
    },
    enableAlg(arg) {
      this.index++;
      this.$h
        .post("/competitors/enable-alg", { alg: arg[1], enable: arg[0] })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: r.data.message,
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
          }
          this.index--;
        });
    },
    reloadAlg(alg) {
      this.index++;
      this.priorities = [];
      this.$h.post("/competitors/reload-alg-info", { alg: alg }).then(r => {
        if (r.data.error) {
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: r.data.message,
            timeout: 2000
          });
        } else {
          Notify.create({
            position: "top",
            color: "green",
            message: "Сохранено",
            timeout: 2000
          });
          alg.reload = true;
        }
        this.index--;
      });
    }
  }
};
</script>
