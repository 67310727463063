<template>
  <q-page class="ub-page">
    <div class="q-gutter-md" style="text-align: center">
      <h4 style="text-align: center;font-weight: bold;padding: 25px 0">
        Конкурент "{{
          (comp.name ? comp.name + " " : "") + comp.competitor_id
        }}"
      </h4>
    </div>
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card style="padding: 5px;height: 100%">
        <table style="width: 100%;min-height: 500px">
          <tr class="row">
            <th class="col-1">#</th>
            <th class="col-3">Бренд</th>
            <th class="col-3">Коэф</th>
            <th class="col-3"></th>
          </tr>
          <tr class="row" v-for="b in brands" :key="'b_' + b.brand_id">
            <td class="col-1" style="text-align: center;padding: 5px">
              {{ b.brand_id }}
            </td>
            <td class="col-3" style="text-align: center;padding: 5px">
              {{ b.name }}
            </td>
            <td class="col-3" style="text-align: center;padding: 5px">
              <input type="number" step="0.01" v-model="b.coef" />
            </td>
          </tr>
        </table>

        <div class="row" style="align-items: end;text-align: right">
          <div class="col-5"></div>
          <div class="col-1">
            <q-btn align="right" @click="upCoeff()" color="blue"
              >Сохранить</q-btn
            >
          </div>
        </div>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "coeff",
  data() {
    return {
      brands: [],
      compId: 0,
      comp: {
        name: "",
        competitor_id: 0
      }
    };
  },
  created() {
    this.compId = this.$route.query.id;
    this.$store.state.progress = true;
    this.$h.get(`/competitors/get-info?id=${this.compId}`).then(r => {
      this.comp = r.data.data;
    });
  },
  mounted() {
    this.getBrands();
  },
  methods: {
    getBrands() {
      this.$store.state.progress = true;
      this.$h.get(`/competitors/get-brand-coeff?id=${this.compId}`).then(r => {
        this.brands = r.data.data.brands;
        this.$store.state.progress = false;
      });
    },
    upCoeff() {
      this.$store.state.progress = true;
      this.$h
        .post(`/competitors/set-brand-coeff?id=${this.compId}`, {
          brands: this.brands
        })
        .then(r => {
          this.brands = r.data.data.brands;
          this.$store.state.progress = false;
        });
    }
  }
};
</script>
<style scoped></style>
