<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card style="padding: 5px;height: 100%">
        <div class="row">
          <div class="col-12" style="text-align:right ">
            <q-btn
              @click="newNfComp = !newNfComp"
              label="Добавить НФ конкурента"
            />
          </div>
        </div>
        <div class="q-pa-lg flex flex-center">
          <q-pagination v-model="current" :max="maxp" direction-links />
          <select v-model="limit">
            <option value="100">100</option>
            <option value="250">250</option>
            <option value="500">250</option>
            <option value="1000">1000</option>
            <option value="1500">1500</option>
            <option value="2500">2500</option>
            <option value="5000">5000</option>
          </select>
        </div>
        <div class="row" style="padding: 5px">
          <div class="col-1">
            Название, Вн.номер
          </div>
          <div class="col-1">
            Вкл/Выкл
          </div>
          <div class="col-2">
            Алгоритмы
          </div>

          <div class="col-6" style="text-align: center">
            <label>
              Поиск
            </label>
          </div>
        </div>
        <div class="row" style="padding: 5px">
          <div class="col-1">
            <q-checkbox
              toggle-indeterminate
              :label="setText"
              v-model="onlySet"
              style="float: left"
            />
          </div>
          <div class="col-1">
            <q-checkbox
              toggle-indeterminate
              :label="enText"
              v-model="enSet"
              style="float: left"
            />
          </div>
          <div class="col-2">
            <q-checkbox
              toggle-indeterminate
              :label="algText"
              v-model.lazy="algSet"
              style="float: left"
            />
          </div>

          <div class="col-6" style="display: flex">
            <input
              id="searchComp"
              :disabled="$store.state.progress"
              v-model="searchNum"
              style="width: 300px;text-align: center;margin: auto"
            />
          </div>
          <div class="col-2">
            <q-btn size="xl" @click="getFile" class="f_type_b_exp">
              <div style="padding: 0 5px">Скачать</div>
            </q-btn>
          </div>
          <vue-excel-xlsx
            :hidden="true"
            class="f_type_b_exp"
            id="btn_xlsx"
            :data="xlData"
            :columns="xlxColumn"
            file-name="Конкуренты"
            :file-type="'xlsx'"
            sheet-name="exel"
          >
            Скачать
          </vue-excel-xlsx>
        </div>
        <table style="width: 100%">
          <tr class="row" style="padding: 5px 0;text-align: center">
            <th class="col-1">
              Номер
            </th>
            <th class="col-1">Вн Номер</th>
            <th class="col-2">Назание</th>
            <th class="col-2">Примечание</th>
            <th class="col-2">Реагируем</th>
            <th class="col-1">Вкл/Выкл</th>
            <th class="col-1">Настройки конкурента</th>
            <th class="col-1">Действие</th>
            <th class="col-1"></th>
          </tr>

          <tr v-for="c in competitors" :key="c.competitor_id" class="row">
            <td class="col-1" style="text-align: center">
              <div class="comp_number">{{ c.competitor_id }}</div>
            </td>
            <td class="col-1">
              <q-input
                outlined
                step="0.01"
                type="number"
                v-model="c.in_id"
                :dense="true"
              />
            </td>
            <td class="col-2">
              <q-input outlined v-model="c.name" :dense="true" />
            </td>
            <td class="col-2">
              <q-input outlined v-model="c.description" :dense="true" />
            </td>
            <td class="col-2" style="text-align: center">
              <div style="text-align: center">
                {{ c.prices }}
              </div>
            </td>
            <td class="col-1" style="text-align: center">
              <div class="q-pa-md">
                <div class="q-gutter-sm">
                  <q-checkbox v-model="c.enable" color="blue" />
                </div>
              </div>
            </td>
            <td class="col-1" style="text-align: center">
              <div class="comp_number">
                <div class="q-pa-md q-gutter-sm">
                  <q-btn
                    size="xs"
                    color="blue"
                    @click="
                      $router.push(
                        `/competitor/algorithms?cid=${c.competitor_id}`
                      )
                    "
                    >Настроить
                  </q-btn>
                </div>
              </div>
            </td>
            <td class="col-1" style="text-align: center">
              <div class="q-pa-md q-gutter-sm">
                <q-btn size="xs" @click="setCompetitors(c)">Сохранить</q-btn>
              </div>
            </td>
            <td class="col-1" style="text-align: center">
              <div class="q-pa-md q-gutter-sm" v-if="c.type == 'nf'">
                <q-btn size="xs" @click="fileDiag(c.competitor_id)"
                  >Прайс</q-btn
                >
              </div>
            </td>
          </tr>
        </table>
      </q-card>
    </div>
    <q-dialog v-model="newNfComp" persistent style="min-width: 600px">
      <q-card>
        <q-card-section
          class="row items-center"
          v-for="col in nfComp"
          :key="col.field + '_new'"
        >
          <div class="col-12">
            <q-input v-model="col.value" :label="col.label" />
          </div>
        </q-card-section>

        <q-card-actions align="center">
          <q-btn
            flat
            label="Отмена"
            @click="newNfComp = false"
            color="primary"
            v-close-popup
          />
          <q-btn
            flat
            label="Добавить"
            @click="addNfComp"
            color="red"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="fileSend" persistent style="min-width: 600px">
      <q-card>
        <q-card-section class="row items-center">
          <q-form @submit="onSubmit" class="q-pa-md q-gutter-sm">
            <q-input hidden name="comp_id" v-model="price_form.id" />
            <q-file
              name="prices"
              v-model="price_form.file"
              filled
              label="Прайс"
              size="xs"
            />

            <q-btn
              flat
              label="Отмена"
              @click="newNfComp = false"
              color="primary"
              v-close-popup
            />
            <q-btn
              flat
              label="Отправить"
              type="submit"
              color="red"
              v-close-popup
            />
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "competitors",
  components: {},
  data() {
    return {
      fileSend: false,
      price_form: {
        id: 0,
        file: null
      },
      nfComp: [
        {
          label: "Вн Номер",
          field: "in_id",
          value: ""
        },
        {
          label: "Назание",
          field: "name",
          value: ""
        },
        {
          label: "Примечание",
          field: "description",
          value: ""
        }
      ],
      newNfComp: false,
      current: 1,
      maxp: 0,
      limit: 100,
      searchNum: "",
      competitors: {},
      onlySet: null,
      algSet: null,
      enSet: null,
      algText: "Все",
      enText: "Все",
      setText: "Все",
      xlData: [],
      xlxColumn: [
        {
          label: "Номер",
          field: "competitor_id"
        },
        {
          label: "Вн Номер",
          field: "in_id"
        },
        {
          label: "Назание",
          field: "name"
        },
        {
          label: "Примечание",
          field: "description"
        },
        {
          label: "Реагируем",
          field: "prices"
        }
      ],
      index: 0
    };
  },
  created() {
    if (this.$route.query.limit) {
      this.limit = parseInt(this.$route.query.limit);
    }

    if (this.$route.query.algSet) {
      this.algSet = this.$route.query.algSet;
    }
    if (this.$route.query.enSet) {
      this.enSet = this.$route.query.enSet;
    }

    if (this.$route.query.onlySet) {
      this.onlySet = this.$route.query.onlySet;
    }
    if (this.$route.query.search) {
      this.searchNum = this.$route.query.search;
    }
    if (this.$route.query.current) {
      this.current = parseInt(this.$route.query.current);
    }
  },
  mounted() {
    this.getCompetitors();
  },
  watch: {
    searchNum(val) {
      this.$router.push({
        path: this.$route.path,
        query: {
          algSet: this.algSet,
          onlySet: this.onlySet,
          current: this.current,
          search: val,
          enSet: this.enSet,
          limit: this.limit
        }
      });
      if (val.toString().length > 2 || val.toString().length == 0) {
        this.getCompetitors();
      }
    },

    current(val) {
      this.$router.push({
        path: this.$route.path,
        query: {
          algSet: this.algSet,
          onlySet: this.onlySet,
          current: val,
          enSet: this.enSet,
          search: this.searchNum,
          limit: this.limit
        }
      });

      this.getCompetitors();
    },
    onlySet(val) {
      switch (val) {
        case true:
          this.setText = "Заполненые";
          break;
        case false:
          this.setText = "Не заполненые";
          break;
        case null:
          this.setText = "Все";
          break;
      }
      this.$router.push({
        path: this.$route.path,
        query: {
          algSet: this.algSet,
          onlySet: val,
          enSet: this.enSet,
          current: this.current,
          search: this.searchNum,
          limit: this.limit
        }
      });
      this.searchNum = "";
      this.getCompetitors();
    },
    limit(val) {
      this.$router.push({
        path: this.$route.path,
        query: {
          algSet: this.algSet,
          enSet: this.enSet,
          onlySet: this.onlySet,
          current: this.current,
          search: this.searchNum,
          limit: val
        }
      });
      this.getCompetitors();
    },
    algSet(val) {
      switch (val) {
        case true:
          this.algText = "C алгоритмами";
          break;
        case false:
          this.algText = "Без алгоритмов";
          break;
        case null:
          this.algText = "Все";
          break;
      }
      this.$router.push({
        path: this.$route.path,
        query: {
          algSet: val,
          enSet: this.enSet,
          onlySet: this.onlySet,
          current: this.current,
          search: this.searchNum,
          limit: this.limit
        }
      });
      this.searchNum = "";
      this.getCompetitors();
    },
    enSet(val) {
      switch (val) {
        case true:
          this.enText = "Вкл";
          break;
        case false:
          this.enText = "Выкл";
          break;
        case null:
          this.enText = "Все";
          break;
      }
      this.$router.push({
        path: this.$route.path,
        query: {
          algSet: this.algSet,
          enSet: val,
          onlySet: this.onlySet,
          current: this.current,
          search: this.searchNum,
          limit: this.limit
        }
      });
      this.searchNum = "";
      this.getCompetitors();
    },
    index(val) {
      this.$store.state.progress = val == 0 ? false : true;
    }
  },
  methods: {
    onSubmit(evt) {
      this.$store.state.progress = true;
      const formData = new FormData(evt.target);
      this.$h.defaults.headers.post["Content-Type"] = "multipart/form-data";
      this.$h.post("/settings/comp-prices", formData).then(r => {
        if (r.data.error) {
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: r.data.message,
            timeout: 2000
          });
        } else {
          Notify.create({
            position: "top",
            color: "green",
            message: r.data.message,
            timeout: 2000
          });
        }
        this.$store.state.progress = false;
      });
    },
    fileDiag(id) {
      this.price_form.id = id;
      this.fileSend = true;
    },
    addNfComp() {
      this.index++;
      let comp = {};
      for (let n in this.nfComp) {
        let l = this.nfComp[n].field;
        comp[l] = this.nfComp[n].value;
      }
      this.$h.post(`/settings/add-nf-comp`, { comp: comp }).then(r => {
        if (r.data.error) {
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: r.data.message,
            timeout: 2000
          });
        } else {
          Notify.create({
            position: "top",
            color: "green",
            message: "Сохранено",
            timeout: 2000
          });
        }
        this.index--;
      });
    },
    getCompetitors() {
      this.$store.state.progress = true;
      this.$h
        .get(
          `/settings/get-competitors?limit=${this.limit}&offset=${(this
            .current -
            1) *
            this.limit}&search=${this.searchNum}&alg=${this.algSet}&set=${
            this.onlySet
          }&enable=${this.enSet}`
        )
        .then(r => {
          this.competitors = r.data.data.comp;
          this.competitors.forEach(comp => {
            this.$watch(
              () => [comp.enable, comp.competitor_id],
              this.compChange,
              { deep: true }
            );
          });
          this.xlData = [];
          for (let c in this.competitors) {
            var comp = this.competitors[c];
            this.xlData.push({
              competitor_id: comp.competitor_id,
              description: comp.description,
              in_id: comp.in_id,
              name: comp.name,
              prices: comp.prices
            });
          }
          const m = parseInt(r.data.data.max);
          this.maxp = r.data.data.max > m ? m + 1 : m;
          this.$store.state.progress = false;
          if (this.searchNum.length > 2) {
            setTimeout(function() {
              if (document.getElementById("searchComp")) {
                document.getElementById("searchComp").focus();
              }
            }, 0);
          }
        });
    },
    compChange(en) {
      let id = en[1];
      let enable = en[0];
      this.$h.post(`/settings/comp-enable?id=${id}`, {
        enable: enable ? 1 : 0
      });
    },
    setCompetitors(comp) {
      const nComp = [];
      nComp.push(comp);
      this.$store.state.progress = true;
      this.$h
        .post(
          `/settings/set-competitors?limit=${this.limit}&offset=${(this
            .current -
            1) *
            this.limit}`,
          { c: nComp }
        )
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: "Ошибка",
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
          }
          this.$store.state.progress = false;
        });
    },
    getFile() {
      this.index++;
      setTimeout(() => {
        let link = document.getElementById("btn_xlsx");
        link.click();
        this.index = 0;
      }, 5000);
    }
  }
};
</script>

<style>
.comp_number {
  display: flex;
  width: 100%;
  min-height: 40px;
  text-align: center;
  justify-content: center;
  align-items: center;
}
</style>
