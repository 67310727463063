import Vue from "vue";
import Vuex from "vuex";
import layout from "./modules/layout";
import axios from "axios";
import login from "@/pages/login";
import { Notify } from "quasar";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    subTitle: "",
    status: "",
    progress: false,
    color: "primary",
    error: false,
    message: "",
    token: localStorage.getItem("token") || "",
    user: JSON.parse(localStorage.getItem("user")) || ""
  },
  modules: {
    layout
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
      state.progress = true;
    },
    auth_success(state, t) {
      state.status = "success";
      state.progress = false;
      state.token = t.t;
      state.user = JSON.parse(t.u);
    },
    auth_error(state) {
      state.status = "error";
      state.progress = false;
    },
    auth_false(state, message) {
      state.error = true;
      state.message = message;
    },
    logout(state) {
      state.status = "";
      state.token = "";
      state.user = "";
    }
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        var baseUrl = `http://${location.hostname}:8443`;

        axios({ url: `${baseUrl}/users/login`, data: user, method: "POST" })
          .then(resp => {
            if (!resp.data.error) {
              const token = resp.data.data.token;
              const locale = resp.data.data.locale;
              const usr = resp.data.data.user.toString();
              localStorage.setItem("token", token);
              localStorage.setItem("user", usr);
              localStorage.setItem("locale", locale);
              axios.defaults.headers.common["Ltoken"] = "token " + token;
              commit("auth_success", { t: token, u: usr });
              resolve(resp);
            } else {
              Notify.create({
                position: "top",
                type: "errors",
                message: resp.data.message,
                timeout: 2000
              });
            }
          })
          .catch(err => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    register({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        var baseUrl = `http://${location.hostname}:8443`;
        axios({
          url: `${baseUrl}/users/registration`,
          data: user,
          method: "POST"
        })
          .then(resp => {
            if (!resp.data.error) {
              const token = resp.data.data.token;
              const locale = resp.data.data.locale;
              const usr = resp.data.data.user.toString();
              localStorage.setItem("token", token);
              localStorage.setItem("user", usr);
              localStorage.setItem("locale", locale);
              axios.defaults.headers.common["Ltoken"] = "token " + token;
              commit("auth_success", { t: token, u: usr });
              resolve(resp);
            } else {
              Notify.create({
                position: "top",
                type: "errors",
                message: resp.data.message,
                timeout: 2000
              });
            }
          })
          .catch(err => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("logout");
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        delete axios.defaults.headers.common["Ltoken"];
        resolve();
      });
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status
  }
});
