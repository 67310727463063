<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <Algorithms />
    </div>
  </q-page>
</template>

<script>
import Algorithms from "@/components/adjustments/algorithms.vue";

export default {
  name: "adjustments",
  components: { Algorithms },
  data: () => {
    return {
      tabs: [],
      tab: 0
    };
  }
};
</script>

<style scoped></style>
