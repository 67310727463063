<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card>
        <div class="row rwcentr" style="padding-bottom: 25px">
          <div class="col-1"></div>
          <div class="col-2">
            <q-select
              sm
              style="margin: 5px"
              filled
              v-model="diapason"
              :options="brands"
              label="Бренд"
            />
          </div>
          <div class="col-3">
            <q-btn @click="addDiapason">Привязать диапазон</q-btn>
          </div>
          <div class="col-1"></div>
          <div class="col-2"></div>
        </div>
        <hr />
        <div>
          <div class="row rwcentr">
            <div class="col-1"></div>
            <div class="col-1">
              <q-input
                type="number"
                id="p_min"
                :min="parseInt(pmin)"
                name="p_min"
                v-model="min"
                :color="parseInt(min) >= parseInt(max) ? 'red' : 'green'"
                label="Мин"
              />
            </div>
            <div class="col-1"></div>
            <div class="col-1">
              <q-input
                type="number"
                id="p_max"
                name="p_max"
                v-model="max"
                :color="parseInt(max) <= parseInt(min) ? 'red' : 'green'"
                label="Макс"
              />
            </div>
            <div class="col-1"></div>
            <div class="col-1">
              <q-input
                type="number"
                mask="#,###"
                id="exmax"
                name="exmax"
                v-model="echarge"
                label="Наценка"
              />
            </div>
            <div class="col-1"></div>
            <div class="col-1">
              <q-input
                type="number"
                mask="#,###"
                id="fault"
                name="fault"
                v-model="fault"
                label="Погрешность"
              />
            </div>
            <div class="col-1"></div>
            <div class="col-2">
              <q-btn
                :disable="parseInt(min) >= parseInt(max)"
                @click="addMinMax"
                >Добавить значение</q-btn
              >
            </div>
          </div>
          <q-splitter v-model="splitterModel">
            <template v-slot:before>
              <q-tabs v-model="tab" vertical class="text-teal">
                <q-tab
                  v-for="r in diapasons"
                  :name="r.id"
                  :label="r.name"
                  :key="r.id"
                />
              </q-tabs>
            </template>

            <template v-slot:after>
              <q-tab-panels
                v-model="tab"
                swipeable
                vertical
                transition-prev="jump-up"
                transition-next="jump-up"
                :draggable="false"
              >
                <q-tab-panel
                  v-for="d in diapasons"
                  :name="d.id"
                  :label="d.name"
                  :key="d.id + Math.random()"
                >
                  <div class="row" v-if="info.length">
                    <div class="col-10">
                      <table class="table_d">
                        <thead>
                          <tr>
                            <th
                              style="text-align: center"
                              v-for="(i, r) in info[0]"
                              :key="Math.random() + i"
                            >
                              {{ $t("table_d")[r] }}
                            </th>
                            <th></th>
                          </tr>
                        </thead>

                        <tr
                          v-for="(i, ind) in info"
                          :key="Math.random() + i.d_id"
                        >
                          <td v-for="(t, r) in i" :key="Math.random() + r">
                            <div v-if="r == 'd_id'">{{ ind + 1 }}</div>
                            <div v-else-if="r == 'id'" hidden>
                              {{ ind + 1 }}
                            </div>
                            <div v-else-if="r == 'echarge'">
                              <input
                                style="width: 75px;"
                                type="number"
                                :id="'echarge_' + ind + '_' + r"
                                :name="'echarge_' + ind + '_' + r"
                                dense
                                mask="#,######"
                                step="0.0001"
                                :ref="'echarge_' + ind + '_' + r"
                                v-model.lazy="values[ind]"
                                @change="setEcharge(info[ind], values[ind])"
                              />
                            </div>
                            <div v-else-if="r == 'fault'">
                              <input
                                style="width: 75px;"
                                type="number"
                                :id="'echarge_' + ind + '_' + r"
                                :name="'echarge_' + ind + '_' + r"
                                dense
                                mask="#,######"
                                step="0.0001"
                                :ref="'echarge_' + ind + '_' + r"
                                v-model.lazy="faults[ind]"
                                @change="setFaults(info[ind], faults[ind])"
                              />
                            </div>
                            <div v-else>{{ t }}</div>
                          </td>
                          <td>
                            <q-btn color="red" @click="delInfo(ind)"
                              >Удалить</q-btn
                            >
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="col-2" style="text-align: right">
                      <q-btn color="green" @click="reSearch">Просчитать</q-btn>
                    </div>
                  </div>
                  <div
                    style="display: flex;justify-content: end;padding-top: 20px"
                  >
                    <q-btn @click="setDiap(d)">Сохранить {{ d.name }}</q-btn>
                    <q-btn color="red" v-if="d.id != 1" @click="delDiap(d)"
                      >Удалить {{ d.name }}</q-btn
                    >
                  </div>
                </q-tab-panel>
              </q-tab-panels>
            </template>
          </q-splitter>
        </div>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "rangers",
  components: {},
  data() {
    return {
      info: [],
      values: [],
      faults: [],
      splitterModel: 10,
      pmin: 0,
      min: 0,
      max: 0,
      echarge: 0.0,
      fault: 0.0,
      diapasons: [],
      diapason: null,
      brands: [{ label: "Стандарт", value: 0 }],
      tab: "",
      index: 0
    };
  },
  created() {
    this.getDiapasons();
  },
  mounted() {
    this.getBrands();
  },
  watch: {
    tab(val) {
      this.getDiapasonInfo(val);
    },
    min(val) {},
    index(val) {
      this.$store.state.progress = val == 0 ? false : true;
    }
  },
  methods: {
    delInfo(ind) {
      this.info.splice(ind, 1);
      this.setDiap(this.diapasons[this.tab - 1]);
    },
    addMinMax() {
      for (let v in this.info) {
        if (this.info[v].min <= this.max) {
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: "Не правильное значение",
            timeout: 2000
          });
          return;
        }
      }

      this.info.push({
        d_id: 0,
        dmin: parseInt(this.min),
        dmax: parseInt(this.max),
        dcount: 0,
        echarge: this.echarge,
        fault: this.fault
      });
      this.pmin = this.min = parseInt(this.max) + 1;
      this.setDiap(this.diapasons[this.tab - 1]);
      // this.setDiap()
    },
    getDiapasons() {
      this.index++;
      this.$h
        .get("/settings/get-diapasons")
        .then(r => {
          this.diapasons = r.data.data.diap;
          if (this.diapasons.length) {
            this.tab = this.diapasons[0].id;
          }
        })
        .then(() => {
          this.index--;
        });
    },
    getDiapasonInfo(id) {
      this.index++;
      this.$h.get(`/settings/get-diapason-info?id=${id}`).then(r => {
        this.info = r.data.data;
        for (let i in this.info) {
          this.values[i] = parseFloat(this.info[i].echarge);
          this.faults[i] = parseFloat(this.info[i].fault);
        }
        const len = this.info.length;
        if (len) {
          this.min = parseInt(this.info[len - 1].dmax) + 1;
        } else {
          this.min = 0;
        }
        this.index--;
      });
    },
    getBrands() {
      this.$h.get("/settings/get-brands").then(r => {
        const br = r.data.data;
        for (let b in br) {
          this.brands.push(br[b]);
        }
      });
    },
    addDiapason() {
      this.index++;
      this.$h
        .post("/settings/add-diapason", { diap: this.diapason })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: r.data.message,
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
            this.diapasons = r.data.data;
          }
          this.index--;
        });
    },
    setEcharge(el, v) {
      el.echarge = v;
    },
    setFaults(el, v) {
      el.fault = v;
    },
    setDiap(d) {
      this.index++;
      this.$h
        .post("/settings/set-diapason", { diap: d, info: this.info })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: r.data.message,
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
            this.diapasons = r.data.data;
          }
          this.index--;
        });
    },

    delDiap(d) {
      this.index++;
      this.$h.post("/settings/del-diapason", { diap: d }).then(r => {
        if (r.data.error) {
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: r.data.message,
            timeout: 2000
          });
        } else {
          Notify.create({
            position: "top",
            color: "green",
            message: "Удаленно",
            timeout: 2000
          });
          this.diapasons = r.data.data;
        }
        this.index--;
      });
    },
    reSearch() {
      this.index++;
      this.$h.post("/settings/search-diapason", { diap: this.tab }).then(r => {
        if (r.data.error) {
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: r.data.message,
            timeout: 2000
          });
        } else {
          Notify.create({
            position: "top",
            color: "green",
            message: "Успешно",
            timeout: 2000
          });
          this.info = r.data.data;
          for (let i in this.info) {
            this.values[i] = parseFloat(this.info[i].echarge);
            this.faults[i] = parseFloat(this.info[i].fault);
          }
        }
        this.index--;
      });
    }
  }
};
</script>

<style scoped>
.rwcentr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
}

.table_d {
  border: 1px solid #eee;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 20px;
}

.table_d th {
  font-weight: bold;
  padding: 5px;
  background: #efefef;
  border: 1px solid #dddddd;
}

.table_d td {
  padding: 5px 10px;
  border: 1px solid #eee;
  text-align: center;
}

.table_d tbody tr:nth-child(odd) {
  background: #fff;
}

.table_d tbody tr:nth-child(even) {
  background: #f7f7f7;
}
</style>
