<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card>
        <q-tabs
          v-model="tab"
          dense
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
        >
          <q-tab
            :name="number"
            :label="p.label"
            v-for="(p, number) in tabs"
            :key="number + Math.random()"
          />
        </q-tabs>

        <q-separator />
        <q-tab-panels v-model="tab" animated style="min-height: 640px">
          <q-tab-panel
            v-for="(p, number) in tabs"
            :name="number"
            :key="number + p"
          >
            <Best_price :f-name="p.label" v-if="p.name == 'best_price'" />
            <Parser :f-name="p.label" v-else-if="p.name == 'parsing'" />
            <Ch_marg :f-name="p.label" v-else-if="p.name == 'ch_marg'" />
            <No_comp :f-name="p.label" v-else-if="p.name == 'no_comp'" />
            <Portfolio :f-name="p.label" v-else-if="p.name == 'portfolio'" />
          </q-tab-panel>
        </q-tab-panels>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import Best_price from "@/components/result/best_price.vue";
import Parser from "@/components/result/parser.vue";
import No_comp from "@/components/result/no_comp.vue";
import Portfolio from "@/components/result/portfolio.vue";
import Ch_marg from "@/components/result/ch_marg.vue";

export default {
  name: "result",
  components: { Ch_marg, Portfolio, No_comp, Parser, Best_price },
  data: () => {
    return {
      tabs: [
        {
          label: "Лучшие цены",
          name: "best_price"
        },
        {
          label: "Результат парсинга",
          name: "parsing"
        },
        {
          label: "Портфели конкурентов",
          name: "portfolio"
        },
        {
          label: "Изменение маржи",
          name: "ch_marg"
        },
        {
          label: "Нет Конкурентов",
          name: "no_comp"
        }
      ],
      tab: 0
    };
  }
};
</script>

<style scoped></style>
