<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <div class="q-gutter-md" style="text-align: center">
        <h4 style="text-align: center;font-weight: bold">
          Алгоритм "{{ alg.name }} - {{ alg.price }}"
        </h4>
      </div>
      <q-card style="min-height: 650px">
        <div class="q-pa-md example-column-equal-width">
          <div class="row" style="text-align: center">
            <div class="col-1">Все кроме</div>
          </div>
          <div class="row">
            <div class="col-1" style="text-align: center">
              <q-checkbox v-model="alg.cats_add" />
            </div>
            <div class="col-6">
              <q-select
                v-model="algCats"
                :options="categories"
                label="Добавить категорию"
                filled
                multiple
                emit-value
                map-options
                dense
                use-chips
                input-debounce="0"
              >
                <template
                  v-slot:option="{
                    itemProps,
                    itemEvents,
                    opt,
                    selected,
                    toggleOption
                  }"
                >
                  <q-item v-bind="itemProps" v-on="itemEvents">
                    <q-item-section>
                      <q-item-label v-html="opt.label"></q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-toggle :value="selected" @input="toggleOption(opt)" />
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
            <div class="col-3">
              <q-select
                filled
                v-model="searchCat"
                use-input
                dense
                hide-selected
                input-debounce="0"
                label="Найти категорию"
                :options="optionsCat"
                @filter="filterCat"
                style="width: 250px"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      No results
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
            <div class="col-2">
              <q-btn
                v-if="algCats.length < categories.length"
                @click="selectAllCats"
                >Выбрать все</q-btn
              >
              <q-btn v-else @click="algCats = []">Убрать все</q-btn>
            </div>
          </div>
          <div class="row">
            <div class="col-1" style="text-align: center">
              <q-checkbox v-model="alg.city_add" />
            </div>
            <div class="col-6">
              <q-select
                filled
                v-model="regCity"
                :options="cities"
                label="Добавить город"
                multiple
                emit-value
                map-options
                dense
                use-chips
                input-debounce="0"
              >
                <template
                  v-slot:option="{
                    itemProps,
                    itemEvents,
                    opt,
                    selected,
                    toggleOption
                  }"
                >
                  <q-item v-bind="itemProps" v-on="itemEvents">
                    <q-item-section>
                      <q-item-label v-html="opt.label"></q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-toggle :value="selected" @input="toggleOption(opt)" />
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
            <div class="col-3">
              <q-select
                filled
                v-model="searchCity"
                use-input
                dense
                hide-selected
                input-debounce="0"
                label="Найти город"
                :options="optionsCity"
                @filter="filterCity"
                style="width: 250px"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      No results
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
            <div class="col-2">
              <q-btn
                v-if="regCity.length < cities.length"
                @click="selectAllCities"
                >Выбрать все</q-btn
              >
              <q-btn v-else @click="regCity = []">Убрать все</q-btn>
            </div>
          </div>
          <div class="row">
            <div class="col-1" style="text-align: center">
              <q-checkbox v-model="alg.comp_add" />
            </div>
            <div class="col-6">
              <q-select
                filled
                v-model="cityCompet"
                :options="competitors"
                label="Добавить конкурента"
                multiple
                emit-value
                map-options
                dense
                use-chips
                input-debounce="0"
              >
                <template
                  v-slot:option="{
                    itemProps,
                    itemEvents,
                    opt,
                    selected,
                    toggleOption
                  }"
                >
                  <q-item v-bind="itemProps" v-on="itemEvents">
                    <q-item-section>
                      <q-item-label v-html="opt.label"></q-item-label>
                    </q-item-section>
                    <q-item-section side>
                      <q-toggle :value="selected" @input="toggleOption(opt)" />
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
            <div class="col-3">
              <q-select
                filled
                v-model="searchComp"
                use-input
                dense
                hide-selected
                input-debounce="0"
                label="Найти конкурента"
                :options="optionsComp"
                @filter="filterComp"
                style="width: 250px"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      No results
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
            <div class="col-2">
              <q-btn
                v-if="cityCompet.length < competitors.length"
                @click="selectAllCompet"
                >Выбрать все</q-btn
              >
              <q-btn v-else @click="cityCompet = []">Убрать все</q-btn>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <formula
                v-if="index == 0"
                :formulas.sync="formula"
                :arr-types="items"
                :statuses="statuses"
                :projects="projects"
                :priceName="alg.price"
                @formulaUpdate="upFormula"
              />
            </div>
          </div>
          <div
            class="row"
            style="text-align: center;padding: 15px 0;display: block"
          >
            <q-btn color="blue" align="right" @click="saveAlg">Сохранить</q-btn>
          </div>
        </div>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import { Notify } from "quasar";
import Formula from "@/components/prices/formula.vue";
export default {
  name: "edit_algorithms",
  components: { Formula },
  data() {
    return {
      searchCat: null,
      searchCity: null,
      searchComp: null,
      optionsCat: [],
      optionsCatList: [],
      optionsCity: [],
      optionsCityList: [],
      optionsComp: [],
      optionsCompList: [],
      statuses: [],
      projects: [],
      alg: "",
      id: null,
      regCity: [],
      algCats: [],
      cityCompet: [],
      categories: [],
      cities: [],
      competitors: [],
      items: {},
      formula: [
        {
          condition: null,
          slave: 0,
          status: [],
          formula: { calc: [], cond: [] }
        }
      ],
      index: 0
    };
  },
  created() {
    this.id = this.$route.query.id;
    if (this.id) {
      this.index++;
      this.$h
        .get(`/algorithms/get-info?id=${this.id}`)
        .then(r => {
          this.alg = r.data.data;
        })
        .then(() => {
          this.getItems();
          this.index--;
        });
    }
  },
  mounted() {
    this.getCities();
    this.getCompetitorsInCity();
  },
  watch: {
    index(val) {
      this.$store.state.progress = val == 0 ? false : true;
    },
    searchCat(val) {
      for (let c in this.categories) {
        if (this.categories[c].label === val) {
          if (this.algCats.indexOf(this.categories[c].value) == -1) {
            this.algCats.push(this.categories[c].value);
          }
        }
      }
    },
    searchCity(val) {
      for (let c in this.cities) {
        if (this.cities[c].label === val) {
          if (this.regCity.indexOf(this.cities[c].value) == -1) {
            this.regCity.push(this.cities[c].value);
          }
        }
      }
    },
    searchComp(val) {
      for (let c in this.competitors) {
        if (this.competitors[c].label === val) {
          if (this.cityCompet.indexOf(this.competitors[c].value) == -1) {
            this.cityCompet.push(this.competitors[c].value);
          }
        }
      }
    }
  },
  methods: {
    upFormula(val) {
      this.formula = val.form;
    },
    getItems() {
      this.index++;
      this.$h.get(`/algorithms/get-items?id=${this.id}`).then(r => {
        const cats = r.data.data.cats;
        const set = r.data.data.setting;
        this.items = r.data.data.items;
        this.regCity = set.cities || [];
        this.cityCompet = set.competitors || [];
        this.statuses = r.data.data.statuses || [];
        this.projects = r.data.data.projects || [];
        this.algCats = set.cats || [];
        for (let c in cats) {
          this.optionsCatList.push(cats[c].name);
          this.optionsCat.push(cats[c].name);
          this.categories.push({
            label: cats[c].name,
            value: cats[c].id
          });
        }
        const fomula = r.data.data.formula;
        if (fomula) {
          this.formula = fomula;
        }

        this.index--;
      });
    },
    filterCat(val, update) {
      if (val === "") {
        update(() => {
          this.optionsCat = this.optionsCatList;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.optionsCat = this.optionsCatList.filter(
          v => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    filterComp(val, update) {
      if (val === "") {
        update(() => {
          this.optionsComp = this.optionsCompList;

          // with Quasar v1.7.4+
          // here you have access to "ref" which
          // is the Vue reference of the QSelect
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.optionsComp = this.optionsCompList.filter(
          v => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    filterCity(val, update) {
      if (val === "") {
        update(() => {
          this.optionsCity = this.optionsCityList;

          // with Quasar v1.7.4+
          // here you have access to "ref" which
          // is the Vue reference of the QSelect
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.optionsCity = this.optionsCityList.filter(
          v => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    getCities() {
      this.index++;
      this.$h.get(`/algorithms/get-cities`).then(r => {
        const cits = r.data.data;
        for (let c in cits) {
          this.optionsCityList.push(cits[c].name);
          this.cities.push({
            label: cits[c].name,
            value: cits[c].city_id
          });
        }
        this.index--;
        this.optionsCity = this.optionsCityList;
      });
    },
    selectAllCities() {
      for (let c in this.cities) {
        let citId = this.cities[c].value;
        if (this.regCity.indexOf(citId) == -1) {
          this.regCity.push(citId);
        }
      }
    },
    selectAllCats() {
      for (let c in this.categories) {
        let citId = this.categories[c].value;
        if (this.algCats.indexOf(citId) == -1) {
          this.algCats.push(citId);
        }
      }
    },
    selectAllCompet() {
      for (let c in this.competitors) {
        let citId = this.competitors[c].value;
        if (this.cityCompet.indexOf(citId) == -1) {
          this.cityCompet.push(citId);
        }
      }
    },
    getCompetitorsInCity() {
      this.index++;
      this.$h.get(`/algorithms/get-comps?algId=${this.id}`).then(r => {
        const compet = r.data.data;
        for (let c in compet) {
          let add = true;
          this.optionsCompList.push(compet[c].competitor);
          let compt = {
            label: compet[c].competitor,
            value: compet[c].id
          };
          for (let cp in this.competitors) {
            if (this.competitors[cp].value == compt.value) {
              add = false;
            }
          }
          if (add) {
            this.competitors.push(compt);
          }
        }
        this.optionsComp = this.optionsCompList;
        this.index--;
      });
    },
    saveAlg() {
      this.index++;
      this.$h
        .post(`/algorithms/update-info?id=${this.id}`, {
          alg: this.alg,
          cats: this.algCats,
          cities: this.regCity,
          comps: this.cityCompet,
          formula: this.formula
        })
        .then(r => {
          this.index--;
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: r.data.message,
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
          }
        });
    }
  }
};
</script>
