<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card style="padding: 5px;height: 100%">
        <div class="q-pa-lg">
          <q-timeline
            style="height: 600px;overflow: auto"
            :layout="layout"
            :side="side"
            color="secondary"
          >
            <q-timeline-entry
              v-for="m in messages"
              title=""
              :key="m.date + Math.random()"
              :subtitle="m.date"
              side="left"
            >
              <div v-html="m.text" />
            </q-timeline-entry>
          </q-timeline>
        </div>
      </q-card>
    </div>
  </q-page>
</template>

<script>
export default {
  name: "main_page",
  props: {
    subTitle: null
  },
  data() {
    return {
      layout: "dense",
      side: "right",
      index: 0,
      messages: []
    };
  },
  watch: {
    index(val) {
      this.$store.state.progress = val == 0 ? false : true;
    }
  },
  created() {},
  mounted() {
    this.getMessages();
  },
  methods: {
    getMessages() {
      this.index++;
      this.$h.get(`/main/messages`).then(r => {
        this.messages = r.data.data;
        this.index--;
      });
    }
  }
};
</script>

<style>
.time_div {
  position: fixed;
  background: #ccc;
  right: 0;
  bottom: 0;
  padding: 20px;
}
</style>
