import Vue from "vue";
import langRu from "./qlang/ru";
import "./styles/quasar.scss";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";
//import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
//import '@quasar/extras/material-icons-round/material-icons-round.css';
//import '@quasar/extras/material-icons-sharp/material-icons-sharp.css';
//import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
//import '@quasar/extras/ionicons-v4/ionicons-v4.css';
//import '@quasar/extras/mdi-v4/mdi-v4.css';
import "@quasar/extras/mdi-v5/mdi-v5.css";

import {
  QCircularProgress,
  QCarousel,
  QCarouselControl,
  QCarouselSlide,
  QForm,
  QFile,
  Quasar,
  Ripple,
  ClosePopup,
  TouchPan,
  LoadingBar,
  Notify,
  Dialog,
  QLayout,
  QHeader,
  QDrawer,
  QPageContainer,
  QPage,
  QPageSticky,
  QToolbar,
  QToolbarTitle,
  QRouteTab,
  QBtn,
  QBtnDropdown,
  QBtnGroup,
  QIcon,
  QList,
  QItem,
  QItemSection,
  QItemLabel,
  QSplitter,
  QScrollArea,
  QExpansionItem,
  QAvatar,
  QBtnToggle,
  QSeparator,
  QTooltip,
  QTable,
  QTd,
  QTh,
  QTr,
  QCard,
  QCardSection,
  QCardActions,
  QSelect,
  QToggle,
  QBadge,
  QSpace,
  QSlider,
  QInput,
  QTabs,
  QTab,
  QTabPanels,
  QTabPanel,
  QDate,
  QTime,
  QPopupProxy,
  QBar,
  QPopupEdit,
  QRadio,
  QResizeObserver,
  QChip,
  QCheckbox,
  QPagination,
  QDialog,
  QVirtualScroll,
  QSkeleton,
  QImg,
  QMenu,
  QBanner,
  QSpinner,
  QInnerLoading,
  QSpinnerGears,
  QSpinnerHourglass,
  QTimeline,
  QTimelineEntry,
  QOptionGroup,
  QSpinnerIos,
  QSpinnerClock
} from "quasar";

Vue.use(Quasar, {
  config: {},
  components: {
    QSpinnerHourglass,
    QSpinnerClock,
    QSpinnerIos,
    QOptionGroup,
    QTimeline,
    QTimelineEntry,
    QInnerLoading,
    QSpinnerGears,
    QCircularProgress,
    QCarousel,
    QCarouselSlide,
    QCarouselControl,
    QForm,
    QFile,
    QLayout,
    QHeader,
    QDrawer,
    QPageContainer,
    QPage,
    QPageSticky,
    QToolbar,
    QToolbarTitle,
    QRouteTab,
    QBtn,
    QBtnDropdown,
    QBtnGroup,
    QIcon,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QSplitter,
    QScrollArea,
    QExpansionItem,
    QAvatar,
    QBtnToggle,
    QSeparator,
    QTooltip,
    QTable,
    QTd,
    QTh,
    QTr,
    QCard,
    QCardSection,
    QCardActions,
    QSelect,
    QSlider,
    QToggle,
    QBadge,
    QSpace,
    QInput,
    QTabs,
    QTab,
    QTabPanels,
    QTabPanel,
    QDate,
    QTime,
    QPopupProxy,
    QBar,
    QPopupEdit,
    QRadio,
    QResizeObserver,
    QChip,
    QCheckbox,
    QPagination,
    QDialog,
    QVirtualScroll,
    QSkeleton,
    QImg,
    QMenu,
    QBanner,
    QSpinner
  },
  directives: {
    Ripple,
    ClosePopup,
    TouchPan
  },
  plugins: {
    LoadingBar,
    Notify,
    Dialog
  },
  lang: langRu
});
