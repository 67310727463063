<template>
  <div>
    <vue-excel-xlsx
      hidden="true"
      class="f_type_b_exp"
      id="btn_xlsx"
      :data="xlData"
      :columns="xlxColumn"
      :file-name="fName + '_' + selectLeb"
      :file-type="'xlsx'"
      sheet-name="exel"
    >
      Скачать
    </vue-excel-xlsx>
    <div :class="mainTabClass">
      <q-input
        v-model="searchItem"
        label="SKU, Арт, Название, Конкурент"
        outlined
        dense
        style="min-width: 200px;width: max-content;padding-top: 10px;margin-left:10px;float: left"
      />
      <q-input
        label="мин"
        v-model="selectTh.min"
        type="number"
        outlined
        dense
        style="min-width: 20px;width: 100px;padding-top: 10px;margin-left:10px;float: left"
      />
      <q-input
        label="макс"
        v-model="selectTh.max"
        type="number"
        outlined
        dense
        style="min-width: 20px;width: 100px;padding-top: 10px;margin-left:10px;float: left"
      />
      <q-btn
        round
        style="margin-top: 10px;float: right;margin-right: 10px"
        icon="delete-variant"
        @click="resetColumn()"
      />
      <q-select
        v-if="index == 0"
        v-model="visibleColumns"
        multiple
        outlined
        dense
        options-dense
        :display-value="$q.lang.table.columns"
        emit-value
        map-options
        :options="columns"
        option-value="name"
        options-cover
        style="min-width: 150px;width: max-content;padding-top: 10px;float: right;margin-right: 10px"
      >
        <template
          v-slot:option="{
            itemProps,
            itemEvents,
            opt,
            selected,
            toggleOption
          }"
        >
          <q-item v-bind="itemProps" v-on="itemEvents" style="width: 150px">
            <q-item-section>
              <q-item-label v-html="opt.label"></q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-toggle :value="selected" @input="toggleOption(opt)" />
            </q-item-section>
          </q-item>
        </template>
      </q-select>
      <q-btn
        round
        style="margin-top: 10px;float: right;margin-right: 10px"
        icon="settings"
        @click="settingHeaders = !settingHeaders"
      />
      <div class="spindiv" v-if="index > 0">
        <q-spinner-ios style="margin: auto" color="primary" size="2em" />
      </div>
      <table v-else style="width: 100%;">
        <thead>
          <tr class="redt">
            <th
              :hidden="!visibleColumns.includes(c.name)"
              v-for="c in columns"
              :class="selectTh.th == c.name ? 'th_select_s' : 'th_select'"
              :key="c.name + Math.random()"
              :style="
                c.name == 'id' || c.name == 'sku_id' ? 'display:none' : ''
              "
            >
              <span @click="sortResult(c.name)">{{ c.label }}</span>
              <select v-if="c.name == 'stat'" v-model="statuse">
                <option v-for="s in statuses" :key="Math.random() + s"
                  >{{ s }}
                </option>
              </select>
              <select
                v-if="c.name == 'cname'"
                style="max-width: 150px"
                v-model="competitor"
              >
                <option v-for="s in competitors" :key="Math.random() + s"
                  >{{ s }}
                </option>
              </select>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="res_tr"
            v-for="(res, ind) in result"
            :key="tab + 'res_' + ind"
            :class="selectSiv == res.id ? 'select_item' : ''"
            :hidden="res.hidden"
          >
            <td
              @click="getSubInfo(res.id, res.sku_id, r, i)"
              :class="'res_td b_' + i"
              v-for="(r, i) in res"
              :key="tab + 'res_' + ind + i"
              :style="i == 'id' || i == 'sku_id' ? 'display:none' : ''"
              :hidden="!visibleColumns.includes(i)"
            >
              <span v-if="i == 'name' || i == 'minc'">
                {{ r.substring(0, 25) + "..." }}
                <q-tooltip
                  anchor="top middle"
                  self="bottom middle"
                  :offset="[10, 10]"
                >
                  <strong>{{ r }}</strong>
                </q-tooltip>
              </span>
              <span
                v-else-if="
                  i == 'link_params' || i == 'link_pred' || i == 'link_alg'
                "
              >
                <span v-html="r" />
              </span>
              <span
                v-else-if="
                  i == 'price' ||
                    i == 'minprice' ||
                    i == 'nprice' ||
                    i == 'countc' ||
                    i == 'minpricec'
                "
                style="display: block;width: 100%;text-align: right"
                >{{ parseInt(r) }}</span
              >
              <span
                v-else-if="i == 'adj_result'"
                style="display: block;width: 100%;text-align: right"
                >{{ parseFloat(r) }}</span
              >
              <span v-else>{{ r }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div :class="showCompClass">
        <div style="padding-top: 15px;font-size: 12px;font-weight: bold">
          <span
            style="cursor: pointer;color: #00bbff"
            @click="showComp = !showComp"
            ><b>{{ showComp ? "-" : "+" }}</b> Все предложения </span
          >({{ subresult.length }})
        </div>
        <div class="tableSubContainer">
          <div class="spindiv" v-if="subindex > 0">
            <q-spinner-ios style="margin: auto" color="primary" size="2em" />
          </div>
          <table v-else style="width: 95%;">
            <thead>
              <tr class="redt">
                <th
                  v-for="c in subcolumns"
                  style="text-align: left"
                  :key="c.name + '_sub'"
                >
                  {{ c.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="res_tr"
                v-for="(res, ind) in subresult"
                :key="tab + 'res_' + ind"
              >
                <td>{{ ind + 1 }}</td>
                <td
                  :class="'res_td b_' + i"
                  v-for="(r, i) in res"
                  :key="tab + 'res_sub' + ind + i"
                  :style="i == 'id' ? 'display:none' : ''"
                >
                  <span v-if="i == 'name' || i == 'minc' || i == 'link_params'">
                    {{ r.substring(0, 25) + "..." }}
                    <q-tooltip
                      anchor="top middle"
                      self="bottom middle"
                      :offset="[10, 10]"
                    >
                      <strong>{{ r }}</strong>
                    </q-tooltip>
                  </span>
                  <span
                    v-else-if="i == 'price' || i == 'SBC_1700742784'"
                    style="text-align: center;display: block"
                    >{{ parseInt(r) }}</span
                  >
                  <span v-else>{{ r }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <q-dialog v-model="settingHeaders">
      <q-layout view="Lhh lpR fff" container class="bg-white">
        <q-page-container>
          <q-page padding>
            <draggable
              :list="columns"
              :disabled="!enabled"
              class="list-group"
              ghost-class="ghost"
              :move="checkMove"
              @start="dragging = true"
              @end="dragging = false"
            >
              <div
                class="list-group-item column_h"
                v-for="element in columns"
                :key="element.name"
              >
                {{ element.label }}
              </div>
            </draggable>
          </q-page>
        </q-page-container>
      </q-layout>
    </q-dialog>
  </div>
</template>

<script>
import { Notify } from "quasar";
import draggable from "vuedraggable";

export default {
  components: { draggable },
  name: "slave_tabs",
  props: {
    tab: { type: Number },
    p: { type: Object },
    fName: { type: String }
  },
  data() {
    return {
      enabled: true,
      settingHeaders: false,
      xlxColumn: [],
      xlData: [],
      searchItem: "",
      selectSiv: 0,
      index: 0,
      showComp: false,
      showAn: false,
      subindex: 0,
      anindex: 0,
      showCompClass: "col-2 thide",
      mainTabClass: "fullPageContainer",
      visibleColumns: [],
      vcol: [],
      columns: [],
      subcolumns: [],
      result: [],
      subresult: [],
      anresult: [],
      ancolumns: [],
      selectTh: {
        min: 0,
        max: 0,
        th: ""
      },
      res: [],
      dragging: false,
      sortBy: "asc",
      competitors: ["Все"],
      statuses: ["Все"],
      statuse: "Все",
      selectLeb: ""
    };
  },
  watch: {
    columns(val) {
      localStorage.setItem("s_table", JSON.stringify(val));
      this.result.splice(0, this.result.length);
      this.xlxColumn.splice(0, this.xlxColumn.length);
      for (let t in this.res) {
        let cl = {};
        for (let c in this.columns) {
          let n = this.columns[c].name;
          cl[n] = this.res[t][n];
        }
        cl["sku_id"] = this.res[t]["sku_id"];
        cl["id"] = this.res[t]["id"];
        cl["hidden"] = false;
        this.result.push(cl);
      }

      for (let c in this.columns) {
        let n = this.columns[c].label;
        if (n != "id" && n != "sku_id") {
          this.xlxColumn.push({
            field: this.columns[c].name,
            label: this.columns[c].label
          });
        }
      }
      this.changeExelData();
    },
    competitor(val) {
      if (val != "Все") {
        this.selectLeb = val;
      } else {
        this.selectLeb = "";
      }
      for (let r in this.result) {
        let res = this.result[r];
        if (val != "Все") {
          this.result[r].hidden = !(res.cname.replace("  ", " ") == val);
        } else {
          this.result[r].hidden = false;
        }
      }
      this.changeExelData();
    },
    statuse(val) {
      if (val != "Все") {
        this.selectLeb = val;
      } else {
        this.selectLeb = "";
      }
      for (let r in this.result) {
        let res = this.result[r];
        if (val != "Все") {
          this.result[r].hidden = !(res.stat.replace("  ", " ") == val);
        } else {
          this.result[r].hidden = false;
        }
      }
      this.changeExelData();
    },
    selectTh: {
      deep: true,
      handler(newDate, oldDate) {
        this.hideColl(newDate);
      }
    },
    visibleColumns(val) {
      this.vcol = [];
      for (let i in val) {
        this.vcol.push(val[i].name);
      }
    },
    searchItem(val) {
      if (val.length) {
        this.selectLeb = val;
        let searchItem = val;
        for (let r in this.result) {
          let res = this.result[r];
          this.result[r].hidden =
            res.onec.toLowerCase().indexOf(searchItem.toLowerCase()) < 0 &&
            res.brand.toLowerCase().indexOf(searchItem.toLowerCase()) < 0 &&
            res.sku.toLowerCase().indexOf(searchItem.toLowerCase()) < 0 &&
            !res.link_params.toLowerCase().includes(searchItem.toLowerCase()) &&
            !res.name.toLowerCase().includes(searchItem.toLowerCase());
        }
      } else {
        this.selectLeb = "";
        for (let r in this.result) {
          this.result[r].hidden = false;
        }
      }
      this.changeExelData();
    },
    index(val) {
      this.$store.state.progress = val == 0 ? false : true;
    },
    showComp(val) {
      if (val) {
        this.mainTabClass = "tableContainer";
        this.showCompClass = "col-12";
      } else {
        this.mainTabClass = "fullPageContainer";
        this.showCompClass = "col-1 thide";
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    changeExelData() {
      this.xlData.splice(0, this.xlData.length);
      for (let t in this.result) {
        if (this.result[t].hidden === false) {
          let dl = {};
          for (let c in this.columns) {
            let n = this.columns[c].name;
            if (n != "id" && n != "sku_id" && !n.includes("link_")) {
              dl[n] = this.result[t][n];
            } else if (n.includes("link_")) {
              let ln = this.result[t][n];
              if (ln) {
                dl[n] = ln.slice(ln.indexOf("<b>") + 3, ln.indexOf("</b>"));
              }
            }
          }
          this.xlData.push(dl);
        }
      }
    },
    checkMove() {},
    resetColumn() {
      localStorage.removeItem("s_table");
      location.reload();
    },
    sortResult(name) {
      this.selectTh.th = name;
      this.selectTh.min = this.selectTh.max = 0;
      this.sortObjects(this.result, name + " " + this.sortBy);
      this.sortBy = this.sortBy == "asc" ? "desc" : "asc";
    },
    hideColl(res) {
      const col = this.selectTh.th;
      const min = this.selectTh.min;
      const max = this.selectTh.max;
      for (let r in this.result) {
        this.result[r].hidden =
          (min > 0 && min > this.result[r][col]) ||
          (max > 0 && max < this.result[r][col]);
      }
      return false;
    },
    sortObjects(objArray, properties /*, primers*/) {
      var primers = arguments[2] || {};
      this.index++;
      properties = properties.split(/\s*,\s*/).map(function(prop) {
        prop = prop.match(/^([^\s]+)(\s*desc)?/i);
        if (prop[2] && prop[2].toLowerCase() === " desc") {
          return [prop[1], -1];
        } else {
          return [prop[1], 1];
        }
      });

      function valueCmp(x, y) {
        return x > y ? 1 : x < y ? -1 : 0;
      }

      function arrayCmp(a, b) {
        var arr1 = [],
          arr2 = [];
        properties.forEach(function(prop) {
          var aValue = a[prop[0]],
            bValue = b[prop[0]];
          if (typeof primers[prop[0]] != "undefined") {
            aValue = primers[prop[0]](aValue);
            bValue = primers[prop[0]](bValue);
          }
          arr1.push(prop[1] * valueCmp(aValue, bValue));
          arr2.push(prop[1] * valueCmp(bValue, aValue));
        });
        return arr1 < arr2 ? -1 : 1;
      }

      objArray.sort(function(a, b) {
        return arrayCmp(a, b);
      });
      this.index--;
    },
    init() {
      this.competitors = ["Все"];
      this.statuses = ["Все"];
      this.index++;
      this.$h.get(`/slave-result/get-data?prId=${this.tab}`).then(r => {
        var cols = r.data.data.mcolumn;
        this.res = r.data.data.result;
        for (let c in cols) {
          if (c != "id" && c != "sku_id") {
            this.visibleColumns.push(c);
          }
        }
        if (localStorage.getItem("s_table") == null) {
          for (let c in cols) {
            if (c != "id" && c != "sku_id") {
              this.columns.push({
                label: cols[c],
                name: c
              });
            }
          }
        } else {
          this.columns = JSON.parse(localStorage.getItem("s_table"));
        }

        for (let r in this.res) {
          if (!this.competitors.includes(this.res[r].cname)) {
            this.competitors.push(this.res[r].cname);
          }
          if (!this.statuses.includes(this.res[r].stat)) {
            this.statuses.push(this.res[r].stat);
          }
        }
        this.index--;
      });
    },
    getSubInfo(sku, skid, skuText, type) {
      if (this.subindex) {
        return;
      }
      if (type == "onec" || type == "sku" || type == "name") {
        navigator.clipboard.writeText(skuText);

        Notify.create({
          position: "top",
          color: "green",
          message: "Скопированно \n" + skuText,
          timeout: 2000
        });
      }
      this.selectSiv = sku;
      if (sku !== undefined) {
        this.subcolumns = [];
        this.ancolumns = [];
        this.subindex++;
        this.anindex++;
        this.$h
          .get(`/slave-result/get-main-by-sku?skuId=${sku}&prId=${this.tab}`)
          .then(r => {
            var cols = r.data.data.column;
            for (let c in cols) {
              this.subcolumns.push({
                label: cols[c],
                name: c
              });
            }
            this.subresult = r.data.data.result;
            this.subindex--;
          });
        this.$h
          .get(`/slave-result/get-analog-by-sku?skuId=${skid}&prId=${this.tab}`)
          .then(r => {
            var cols = r.data.data.column;
            for (let c in cols) {
              this.ancolumns.push({
                label: cols[c],
                name: c
              });
            }
            this.anresult = r.data.data.result;
            this.anindex--;
          });
      }
    }
  }
};
</script>

<style>
.spindiv {
  display: flex;
  justify-content: center;
  margin: auto;
  height: 300px;
}

.select_item td {
  background-color: #fff4e3 !important;
}

.tableContainer,
.tableSubContainer {
  overflow: auto;
  height: 350px;
  border: 1px solid;
}

.fullPageContainer {
  overflow: auto;
  height: 650px;
  border: 1px solid;
}

.fullPageContainer,
.tableSubContainer,
.tableSubContainer {
  align-content: center;
  width: 100%;
}

.tableContainer table,
.fullPageContainer table,
.tableSubContainer table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  border-color: gray;
  width: 100%;
}

.fullPageContainer table td,
.fullPageContainer table th,
.tableContainer table td,
.tableContainer table th {
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
  min-width: 50px;
  background: #ffffff;
}

.tableContainer table th[scope="row"],
.tableContainer table thead tr {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
}

.fullPageContainer table th[scope="row"],
.fullPageContainer table thead tr {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
}

.tableSubContainer table td,
.tableSubContainer table th {
  text-align: left;
  padding: 4px;
  min-width: 50px;
  background: #ffffff;
}

.tableSubContainer table th[scope="row"],
.tableSubContainer table thead tr {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
}

.res_tr:hover {
  background-color: #e7e7e7;
  cursor: pointer;
}

.res_tr:hover .res_td {
  cursor: pointer;
  background-color: transparent; /* or #000 */
}

.res_td {
  padding: 5px;
}

.b_link_params,
.b_name,
.b_minc {
  /*
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  */
  white-space: nowrap;
  width: 200px;
}

.b_link_params {
  width: 150px !important;
}

.b_sku_name {
  //width: 250px;
  display: block;
}

.b_alg {
  width: 150px;
  display: table-cell;
  text-align: center;
}

.thide .tableSubContainer {
  display: none;
}

.b_marg,
.b_nmarg {
  text-align: right !important;
}

.th_select {
  color: #0f2027;
  cursor: pointer;
  text-align: left;
}

.th_select_s {
  cursor: pointer;
  color: #00bbff;
  text-align: left;
}

.th_select:hover {
  color: gray;
}

.column_h {
  cursor: pointer;
  min-height: 25px;
  border: 1px solid gray;
  text-align: center;
  /* width: max-content; */
  margin-top: 3px;
}
</style>
