<template>
  <div>
    <div class="content-lg-center">
      <h6 style="text-align: center;font-weight: bold">Настройка формулы</h6>
      <div class="col-12" style="text-align: center;padding-bottom: 15px">
        <input type="button" value="Добавить просчет" @click="addCond" />
      </div>
    </div>
    <div class="row" v-for="(f, i) in editFormuls" :key="i + Math.random()">
      <div
        class="col-12"
        style="padding-bottom:15px;text-align: center;font-weight: bold;font-size: 16px"
      >
        {{ matchCond[f.condition] }}
        <button
          v-if="f.condition"
          style="float: right;font-size: 12px"
          @click="clearCond(f)"
        >
          X
        </button>
      </div>

      <div class="col-1"></div>
      <div class="col-4">
        <select v-model="f.typeF">
          <option
            v-for="(v, i) in arrTypes"
            :key="v + i + Math.random()"
            :value="i"
            >{{ i == "value" ? v + " " + priceName : v }}
          </option>
        </select>
        <input type="button" value="Добавить переменную" @click="addType(f)" />
      </div>
      <div class="col-3" v-if="selectT == 'calc'">
        <input
          v-for="m in matchSimvolCalc"
          type="button"
          :key="m + '_s'"
          :value="m"
          @click="addAction(f, m)"
        />
      </div>
      <div class="col-3" v-if="selectT == 'cond'">
        <input
          v-for="m in matchSimvol"
          type="button"
          :key="m + '_s'"
          :value="m"
          @click="addAction(f, m)"
        />
      </div>
      <div class="col-2">
        <q-select
          v-if="index == 0"
          v-model="f.projects"
          multiple
          outlined
          dense
          options-dense
          display-value="Проекты"
          emit-value
          map-options
          :options="projects"
          option-value="id"
          options-cover
          style="min-width: 150px;width: max-content;float: left;margin-right: 10px"
        >
          <template
            v-slot:option="{
              itemProps,
              itemEvents,
              opt,
              selected,
              toggleOption
            }"
          >
            <q-item v-bind="itemProps" v-on="itemEvents" style="width: 150px">
              <q-item-section>
                <q-item-label v-html="opt.name"></q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-toggle :value="selected" @input="toggleOption(opt)" />
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
      <div class="col-2">
        <q-select
          v-if="index == 0"
          v-model="f.statuses"
          multiple
          outlined
          dense
          options-dense
          display-value="Статусы"
          emit-value
          map-options
          :options="statuses"
          option-value="id"
          options-cover
          style="min-width: 150px;width: max-content;float: left;margin-right: 10px"
        >
          <template
            v-slot:option="{
              itemProps,
              itemEvents,
              opt,
              selected,
              toggleOption
            }"
          >
            <q-item v-bind="itemProps" v-on="itemEvents" style="width: 150px">
              <q-item-section>
                <q-item-label v-html="opt.name"></q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-toggle :value="selected" @input="toggleOption(opt)" />
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
      <div class="col-12">
        <div
          class="row"
          v-for="(t, l) in f.formula"
          :key="t + i + Math.random()"
        >
          <div class="col-1" style="padding: 10px">
            <button
              :style="selectT === l ? 'color: red' : 'color: black'"
              @click="selectT = l"
            >
              {{ $t("formula")[l] }}
            </button>
          </div>
          <div class="col-9" style="font-size: 22px;font-weight: bold">
            <span
              v-for="(inp, ft) in t"
              :key="ft + Math.random()"
              style="padding: 5px"
            >
              <span v-if="inp.type == 'type' && inp.name == 'empt'">
                <label :for="ft + inp.name"></label>
                <input
                  style="width: 75px;min-width: 50px;"
                  type="number"
                  step=".01"
                  :id="ft + inp.name"
                  :name="ft + inp.name"
                  v-model.lazy="f.formula[l][ft].val"
                />
              </span>
              <span v-else-if="inp.type == 'type'">
                {{ arrTypes[inp.val] }}</span
              >
              <span v-else>{{ inp.val }}</span>
            </span>
          </div>
          <div class="col-2">
            <button
              :hidden="l !== selectT"
              v-if="f.formula[l].length"
              style="float: right"
              @click="clearF(f)"
            >
              Очистить
            </button>
          </div>
          <div class="col-1"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "formula",
  components: {},
  props: {
    formulas: [],
    statuses: [],
    projects: [],
    arrTypes: {},
    priceName: null
  },
  mounted() {
    this.editFormuls = this.formulas;
  },
  data() {
    return {
      editFormuls: [],
      selectT: "calc",
      conditionVal: null,
      condition: "and",
      matchSimvolCalc: ["+", "/", "-", "*", "(", ")"],
      matchSimvol: [
        "+",
        "/",
        "-",
        "*",
        "<",
        ">",
        "=",
        "<=",
        ">=",
        "&",
        "||",
        "(",
        ")"
      ],
      matchCond: { or: "или", and: "и" },
      /*
      arrTypes: {
        empt: "Ручной ввод",
        price: "Цена Конк-та",
        price_m: "Цена Наша",
        price_in: "Цена Инет",
        price_opt: "Цена Опт",
        coof: "Коэфициент",
        markup: "Наценка"
      },*/
      index: 0
    };
  },
  watch: {
    editFormuls(val) {
      this.$emit("formulaUpdate", { form: val });
    }
  },
  methods: {
    addAction(f, m) {
      var t = this.selectT;
      var l = f.formula[t].length;
      if (l == 0 && m == "(") {
        f.formula[t].push({ type: "act", name: m, val: m });
      } else if (
        (l && f.formula[t][l - 1].type == "type") ||
        m == ")" ||
        m == "("
      ) {
        f.formula[t].push({ type: "act", name: m, val: m });
      } else if (
        l &&
        f.formula[t][l - 1].type == "act" &&
        (m != ")" || m != "(")
      ) {
        f.formula[t].push({ type: "act", name: m, val: m });
      }
    },
    addType(f) {
      var t = this.selectT;
      var l = f.formula[t].length;
      if (l == 0 || f.formula[t][l - 1].type == "act") {
        if (f.typeF == "empt") {
          f.formula[t].push({ type: "type", name: f.typeF, val: 0 });
        } else {
          f.formula[t].push({ type: "type", name: f.typeF, val: f.typeF });
        }
      }
    },
    addCond() {
      this.editFormuls.push({
        condition: this.condition,
        statuses: [],
        projects: [],
        formula: { calc: [], cond: [] }
      });
    },
    clearF(f) {
      var t = this.selectT;
      f.formula[t].splice(f.formula[t].length - 1, 1);
    },
    clearCond(f) {
      let ind = this.editFormuls.indexOf(f);
      this.editFormuls.splice(ind, 1);
    }
  }
};
</script>

<style scoped></style>
