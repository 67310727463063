<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card>
        <q-tabs
          v-model="tab"
          dense
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
        >
          <q-tab
            :name="p.price_id"
            :label="p.name"
            v-for="p in tabs"
            :key="p.price_id"
          />
        </q-tabs>

        <q-separator />
        <q-tab-panels v-model="tab" animated style="min-height: 640px">
          <q-tab-panel
            v-for="p in tabs"
            :name="p.price_id"
            :key="p.price_id + 't'"
          >
            <div class="row" style="padding-bottom: 25px">
              <div class="col-1"></div>
              <div class="col-2">
                <q-input
                  outlined
                  label="Новый Алгоритм"
                  v-model="algName"
                  :dense="true"
                />
              </div>
              <div class="col-1"></div>
              <div class="col-1">
                <q-btn
                  :disable="algName.length < 3"
                  @click="addAlgorithm(p.price_id)"
                  >Добавить</q-btn
                >
              </div>
              <div class="col-1"></div>
              <div class="col-2"></div>
              <div class="col-4"></div>
            </div>
            <div class="row">
              <div class="col-1" style="text-align: center">
                <b>Приоритет</b>
              </div>
              <div class="col-1" style="text-align: center">
                <b>Вкл/Выкл</b>
              </div>
              <div class="col-3" style="text-align: center">
                <b>Название</b>
              </div>
              <div class="col-2" style="text-align: center"></div>
              <div class="col-2" style="text-align: center"></div>
              <div class="col-1"></div>
              <div class="col-1"></div>
            </div>
            <div
              style="padding-top: 15px"
              class="row"
              v-for="alg in algorithms"
              :key="alg.alg_id + 'a'"
            >
              <div class="col-1" style="text-align: center">
                <select v-model="alg.priority">
                  <option v-for="p in priorities" :key="p + 'p'">{{
                    p
                  }}</option>
                </select>
              </div>
              <div class="col-1" style="text-align: center">
                <input v-model="alg.alg_enable" type="checkbox" />
              </div>
              <div class="col-3" style="text-align: center">
                <b><input style="width: 100%" v-model="alg.name"/></b>
              </div>
              <div class="col-2" style="text-align: center"></div>
              <div class="col-1" style="text-align: center"></div>
              <div class="col-1" style="margin: 0 10px">
                <q-btn
                  @click="
                    $router.push(`/slave_algorithm/edit?id=${alg.alg_id}`)
                  "
                  >Настроить</q-btn
                >
              </div>
              <div class="col-1" style="margin: 0 10px">
                <q-btn color="blue" @click="updateAlg(alg)">Сохранить</q-btn>
              </div>
              <div class="col-1" style="margin: 0 10px">
                <q-btn color="red" @click="deleteDial(alg)">Удалить</q-btn>
              </div>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </q-card>
    </div>
    <q-dialog
      v-model="delConfirm"
      v-if="delAlg"
      persistent
      style="min-width: 300px"
    >
      <q-card>
        <q-card-section class="row items-center">
          <span style="text-align: center"
            >Уверены, что хотите удалить <br /><b>{{ delAlg.name }}</b></span
          >
        </q-card-section>

        <q-card-actions align="center">
          <q-btn
            flat
            label="Отмена"
            @click="delConfirm = false"
            color="primary"
            v-close-popup
          />
          <q-btn
            flat
            label="Удалить"
            @click="deleteAlg"
            color="red"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "algorithms",
  components: {},
  data() {
    return {
      delConfirm: false,
      priorities: [],
      algName: "",
      algorithms: [],
      tab: "",
      tabs: [],
      delAlg: null,
      index: 0
    };
  },
  created() {
    this.getPrices();
  },
  mounted() {},
  watch: {
    tab() {
      this.getAlgorithms();
    },
    index(val) {
      this.$store.state.progress = val == 0 ? false : true;
    }
  },
  methods: {
    deleteDial(alg) {
      this.delConfirm = true;
      this.delAlg = alg;
    },
    deleteAlg() {
      this.index++;
      this.priorities = [];
      this.$h
        .post(`/slave-algorithms/del-alg?pid=${this.tab}`, { alg: this.delAlg })
        .then(r => {
          this.algorithms = r.data.data;
          for (let p in this.algorithms) {
            this.priorities.push(this.algorithms[p].priority);
          }
          this.index--;
        });
    },
    getPrices() {
      this.index++;
      this.$h.get("/slave-algorithms/get-prices").then(r => {
        this.tabs = r.data.data.tabs;
        if (this.tabs.length) {
          this.tab = this.tabs[0].price_id;
        }
        this.index--;
      });
    },
    getAlgorithms() {
      this.index++;
      this.priorities = [];
      this.$h.get(`/slave-algorithms/get-list?pid=${this.tab}`).then(r => {
        this.algorithms = r.data.data;
        this.algorithms.forEach(alg => {
          this.$watch(() => [alg.alg_enable, alg.alg_id], this.enableAlg, {
            deep: true
          });
        });
        for (let p in this.algorithms) {
          this.priorities.push(this.algorithms[p].priority);
        }
        this.index--;
      });
    },
    enableAlg(arg) {
      this.index++;
      this.$h
        .post("/slave-algorithms/enable-alg", { alg: arg[1], enable: arg[0] })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: r.data.message,
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
          }
          this.index--;
        });
    },
    addAlgorithm() {
      this.index++;
      this.priorities = [];
      this.$h
        .post("/slave-algorithms/add", { name: this.algName, pid: this.tab })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: r.data.message,
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
            this.algorithms = r.data.data;
            for (let p in this.algorithms) {
              this.priorities.push(this.algorithms[p].priority);
            }
          }
          this.index--;
        });
    },
    updateAlg(alg) {
      this.index++;
      this.priorities = [];
      this.$h.post("/slave-algorithms/update", { alg: alg }).then(r => {
        if (r.data.error) {
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: r.data.message,
            timeout: 2000
          });
        } else {
          Notify.create({
            position: "top",
            color: "green",
            message: "Сохранено",
            timeout: 2000
          });
          this.algorithms = r.data.data;
          for (let p in this.algorithms) {
            this.priorities.push(this.algorithms[p].priority);
          }
        }
        this.index--;
      });
    }
  }
};
</script>
