<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <q-card style="padding: 5px;height: 100%">
        <div class="row">
          <div class="col-2">
            <q-select
              style="margin: 5px"
              filled
              v-model="region"
              :options="regions"
              label="Регион"
              emit-value
              stack-label
              map-options
            />
          </div>
          <div class="col-2">
            <q-select
              filled
              style="margin: 5px"
              v-model="city"
              :disable="region == null"
              :options="cities"
              label="Город"
              emit-value
              stack-label
              map-options
            />
          </div>
          <div class="col-2">
            <q-select
              style="margin: 5px"
              filled
              outlined
              v-model="competitor"
              :disable="city == null"
              :options="competitors"
              label="Конкурент"
              emit-value
              stack-label
              map-options
            />
          </div>
          <div class="col-3"></div>
          <div class="col-3">
            <q-input
              style="margin: 5px;height: 50px !important;"
              :input-style="{ height: '50px' }"
              outlined
              label="Поик по Sku"
              v-model="skuName"
            >
              <template v-slot:append>
                <q-icon
                  name="close"
                  @click="skuName = ''"
                  class="cursor-pointer"
                />
              </template>
            </q-input>
          </div>
        </div>
      </q-card>
    </div>
    <div class="q-gutter-y-md" style="max-width: 100%;padding-top: 15px">
      <q-card style="padding: 5px;height: 100%">
        <q-table
          style="min-height: 500px"
          :data="products"
          :columns="columns"
          :loading="loading"
          :pagination="initialPagination"
        >
          <template v-slot:header="props">
            <q-tr :props="props">
              <q-th v-for="col in props.cols" :key="col.name" :props="props">
                {{ col.label }}
              </q-th>
            </q-tr>
          </template>
          <template v-slot:body="props">
            <q-tr
              :id="props.row.sub_id"
              :data-set="props.row.sub_id"
              style="cursor: pointer"
              @click="
                () => {
                  props.row.show = !props.row.show;
                  getSub(props.row);
                }
              "
            >
              <q-td v-for="col in props.cols" :key="col.name" :props="props">
                <div v-if="col.name == 'sku_id'">
                  <span style="color: #ff975d" class="material-icons"
                    >{{ !props.row.show ? "add" : "remove" }} </span
                  >{{ col.value }}
                </div>
                <div v-else-if="col.name == 'name'" class="prod_name">
                  {{ col.value }}
                </div>
                <div v-else>{{ col.value }}</div>
              </q-td>
            </q-tr>
            <q-tr v-show="props.row.show && props.row.sub.length > 0">
              <q-td colspan="100%">
                <q-table
                  :data="props.row.sub"
                  :columns="columnsSub"
                  :loading="loading"
                >
                  <template v-slot:header="props">
                    <q-tr :props="props">
                      <q-th
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                      >
                        {{ col.label }}
                      </q-th>
                    </q-tr>
                  </template>
                  <template v-slot:body="props">
                    <q-tr>
                      <q-td
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                      >
                        <div style="text-align: left" v-if="col.name == 't'">
                          <b
                            ><span
                              v-if="col.value == 'up'"
                              style="color: #04ff04"
                              >&#8593;</span
                            >
                            <span
                              v-else-if="col.value == 'down'"
                              style="color: red"
                              >&#8595;</span
                            >
                            <span v-else>--</span>
                          </b>
                        </div>
                        <div v-else>{{ col.value }}</div>
                      </q-td>
                    </q-tr>
                  </template>
                  <template v-slot:bottom class="tt"></template>
                </q-table>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import { product } from "ramda";

export default {
  name: "products",
  props: {
    subTitle: null
  },
  data: () => {
    return {
      shows: [],
      regions: [],
      cities: [],
      competitors: [],
      region: null,
      city: null,
      competitor: null,
      products: [],
      skuName: "",
      loading: false,
      columns: [
        {
          name: "sku_id",
          required: true,
          label: "Id",
          align: "left",
          field: "sku_id",
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "sku",
          align: "center",
          label: "Артикул",
          field: "sku",
          sortable: true
        },
        {
          name: "name",
          align: "left",
          label: "Название",
          field: "name",
          sortable: true
        },
        {
          name: "brand",
          align: "center",
          label: "Бренд",
          field: "brand",
          sortable: true
        },
        {
          name: "mprice_s",
          align: "center",
          label: "Цена",
          field: "mprice_s",
          sortable: true
        },
        {
          name: "mprice",
          align: "center",
          label: "Цена опт",
          field: "mprice",
          sortable: true
        },
        {
          name: "mmarg",
          align: "center",
          label: "Маржа",
          field: "mmarg",
          sortable: true
        },
        {
          name: "cid",
          align: "center",
          label: "Поставщик",
          field: "cid",
          sortable: true
        }
      ],
      columnsSub: [
        {
          name: "sku_id",
          required: true,
          label: "Id",
          align: "left",
          field: "sku_id",
          format: val => `${val}`,
          sortable: true
        },
        {
          name: "sku",
          align: "center",
          label: "Артикул",
          field: "sku",
          sortable: true
        },
        {
          name: "name",
          align: "left",
          label: "Название",
          field: "name",
          sortable: true
        },
        {
          name: "brand",
          align: "center",
          label: "Бренд",
          field: "brand",
          sortable: true
        },
        {
          name: "price",
          align: "center",
          label: "Цена",
          field: "price",
          sortable: true
        },
        {
          name: "t",
          align: "center",
          label: "",
          field: "t",
          sortable: true
        },
        {
          name: "pprice",
          align: "center",
          label: "Цена (пред)",
          field: "pprice",
          sortable: true
        },
        {
          name: "quantity",
          align: "center",
          label: "Кол-во",
          field: "quantity",
          sortable: true
        },
        {
          name: "pquantity",
          align: "center",
          label: "Кол-во (пред)",
          field: "pquantity",
          sortable: true
        },
        {
          name: "cid",
          align: "center",
          label: "Поставщик",
          field: "cid",
          sortable: true
        }
      ],
      data: [],
      initialPagination: {
        sortBy: "desc",
        descending: false,
        page: 0,
        rowsPerPage: 50
        // rowsNumber: xx if getting data from a server
      }
    };
  },
  watch: {
    region() {
      this.getCities();
    },
    city() {
      this.getCompetitors();
    },
    competitor() {
      this.getProducts();
    },
    skuName(val) {
      if (val.length > 3 || val.length == 0) {
        this.getProducts();
      }
    }
  },
  created() {},
  mounted() {
    this.getRegions();
  },
  methods: {
    getSub(ind) {
      if (ind.show) {
        this.getSubProducts(ind, 0);
      }
    },
    getRegions() {
      this.$store.state.progress = true;
      this.$h
        .get("/products/get-regions")
        .then(r => {
          this.regions = r.data.data;
        })
        .then(() => {
          this.$store.state.progress = false;
        });
    },
    getCities() {
      this.$store.state.progress = true;
      this.city = null;
      this.$h
        .get(`/products/get-cities?regId=${this.region}`)
        .then(r => {
          this.cities = r.data.data;
        })
        .then(() => {
          this.$store.state.progress = false;
          this.getProducts();
        });
    },
    getCompetitors() {
      this.$store.state.progress = true;
      this.competitor = null;
      this.$h
        .get(`/products/get-comp-by-cities?id=${this.city}`)
        .then(r => {
          this.competitors = r.data.data;
        })
        .then(() => {
          this.$store.state.progress = false;
          this.getProducts();
        });
    },
    getProducts(skuId = null, main = 1) {
      this.$store.state.progress = true;
      this.loading = true;
      this.$h
        .get(
          `/products/get-sku?regId=${this.region}&citId=${this.city}&compId=${this.competitor}&sku=${this.skuName}
          &main=${main}&skuId=${skuId}`
        )
        .then(r => {
          this.products = r.data.data;
          for (let i in this.products.length) {
            this.shows[i] = false;
          }
        })
        .then(() => {
          this.loading = false;
          this.$store.state.progress = false;
        });
    },
    getSubProducts(ind = null, main = 1) {
      this.$store.state.progress = true;
      this.loading = true;
      this.$h
        .get(
          `/products/get-sub-sku?regId=${this.region}&citId=${this.city}&compId=${this.competitor}&sku=${this.skuName}
          &main=${main}&skuId=${ind.sku_id}`
        )
        .then(r => {
          ind.sub = r.data.data;
        })
        .then(() => {
          this.loading = false;
          this.$store.state.progress = false;
        });
    }
  }
};
</script>

<style scoped>
.prod_name {
  width: 230px; /* Ширина блока */
  word-wrap: break-word; /* Перенос слов */
  word-break: break-all;
  white-space: initial;
  font-weight: bold;
}
</style>
