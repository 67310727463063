<template>
  <q-layout>
    <q-page-container>
      <q-page class="flex flex-center">
        <q-card class="ub-login-card">
          <q-form @submit.prevent="login" v-if="state == 'login'">
            <q-card-section>
              <q-card-section>
                <q-input v-model="email" clearable :label="$t('user_f').email">
                  <template v-slot:prepend>
                    <q-icon name="mdi-account" />
                  </template>
                </q-input>
                <q-input
                  v-model="password"
                  type="password"
                  clearable
                  :label="$t('user_f').password"
                >
                  <template v-slot:prepend>
                    <q-icon name="mdi-lock" />
                  </template>
                </q-input>
              </q-card-section>
            </q-card-section>

            <q-card-actions align="right">
              <q-btn type="submit" color="primary" unelevated>Login</q-btn>
            </q-card-actions>
          </q-form>
          <q-form @submit.prevent="register" v-if="state == 'registr'">
            <q-card-section>
              <q-card-section>
                <q-input
                  v-model="username"
                  :label="$t('user_f').username"
                  aria-required="true"
                  :rules="[val => (val && val.length >= 3) || 'Min length 6']"
                >
                </q-input>
                <q-input
                  v-model="surname"
                  aria-required="true"
                  :label="$t('user_f').surname"
                  :rules="[val => (val && val.length > 3) || 'Min length 3']"
                >
                </q-input>
                <q-input
                  v-model="email"
                  type="email"
                  aria-required="true"
                  :label="$t('user_f').email"
                  :rules="[val => (val && val.length > 0) || 'Min length 6']"
                >
                </q-input>
                <q-input
                  v-model="password"
                  type="password"
                  :label="$t('user_f').password"
                  :rules="[val => (val && val.length >= 6) || 'Min length 6']"
                >
                </q-input>
                <q-input
                  v-model="confpassword"
                  type="password"
                  :label="$t('user_f').cpassword"
                  :rules="[
                    val => (val && val == password) || 'Not equal password'
                  ]"
                >
                </q-input>
              </q-card-section>
            </q-card-section>

            <q-card-actions align="right">
              <q-btn
                type="submit"
                color="primary"
                :disable="
                  password != confpassword ||
                    password.length < 6 ||
                    username.length < 3 ||
                    surname.length < 3
                "
                unelevated
                >Registration</q-btn
              >
            </q-card-actions>
          </q-form>
        </q-card>
      </q-page>
    </q-page-container>
    <q-dialog v-model="$store.state.error">
      <q-card>
        <q-card-section class="q-pt-none">
          {{ $store.state.message }}
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-layout>
</template>

<script>
export default {
  data() {
    return {
      reg: "/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/",
      state: "login",
      phone: "",
      surname: "",
      username: "",
      email: "",
      password: "",
      confpassword: "",
      valid: false
    };
  },
  computed: {
    dark: {
      get() {
        return this.$store.state.layout.dark;
      }
    }
  },
  mounted() {
    this.$q.dark.set(this.dark);
  },
  methods: {
    login: function() {
      let email = this.email;
      let password = this.password;
      this.$store
        .dispatch("login", { email, password })
        .then(() => {
          window.location.href = "/";
        })
        .catch(err => console.log(err));
    },
    register: function() {
      let email = this.email;
      let password = this.password;
      let surname = this.surname;
      let username = this.username;
      this.$store
        .dispatch("register", { email, password, username, surname })
        .then(() => {
          window.location.href = "/";
        })
        .catch(err => console.log(err));
    }
  }
};
</script>

<style lang="scss">
.q-field__marginal {
  background-color: #ffffff !important;
}
.ub-login-card {
  width: 100%;
  max-width: 400px;
  border-radius: 12px !important;

  .ub-login-hdr {
    background-color: #3cb1d7;
    // background: #0f2027; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #3cb1d7,
      #203a43
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #3cb1d7,
      #203a43
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
}
</style>
