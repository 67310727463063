<template>
  <div>
    <div class="row">
      <div class="col-2" style="float: left">
        <q-btn @click="adduser()">Добавить</q-btn>
      </div>
    </div>
    <div class="row" style="text-align: center;padding: 15px">
      <div class="col-1" style="text-align: center">№</div>
      <div class="col-2">
        <b>{{ $t("user").username }}</b>
      </div>
      <div class="col-2">
        <b>{{ $t("user").surname }}</b>
      </div>
      <div class="col-2">
        <b>{{ $t("user").role }}</b>
      </div>
      <div class="col-2">
        <b>{{ $t("user").email }}</b>
      </div>
      <div class="col-1">
        <b>{{ $t("user").status }}</b>
      </div>
      <div class="col-1"></div>
    </div>
    <div v-for="c in users" :key="c.id" class="row">
      <div class="col-1" style="text-align: center">{{ c.id }}</div>
      <div class="col-2">
        <q-input outlined disable :value="c.username" :dense="true" />
      </div>
      <div class="col-2">
        <q-input outlined disable :value="c.surname" :dense="true" />
      </div>
      <div class="col-2">
        <q-input outlined disable :value="c.role" :dense="true" />
      </div>
      <div class="col-2">
        <q-input outlined disable :value="c.email" :dense="true" />
      </div>
      <div class="col-1" style="text-align: center">
        <q-checkbox
          outlined
          type=""
          disable
          :value="c.status > 0 ? true : false"
          :dense="true"
        />
      </div>
      <div class="col-2" style="text-align: center">
        <q-btn @click="setuser(c)">Изменить</q-btn>
      </div>
    </div>
    <q-dialog v-model="show">
      <q-card>
        <q-card-section style="min-width: 400px">
          <div v-for="(u, p) in user" :key="p">
            <div class="row" v-if="p != 'id'">
              <div class="col-4 item_form">
                <div v-if="p == 'password' || p == 'cpassword'">
                  <b :style="saveu ? 'color: red' : ''">{{ $t("user")[p] }}</b>
                </div>
                <div v-else>
                  <b>{{ $t("user")[p] }}</b>
                </div>
              </div>
              <div class="col-8" v-if="p == 'role'">
                <q-select
                  outlined
                  v-model="user[p]"
                  :name="p"
                  :options="roles"
                  :dense="true"
                />
              </div>
              <div
                class="col-8"
                v-else-if="p == 'password' || p == 'cpassword'"
              >
                <q-input
                  outlined
                  bg-color="green"
                  type="text"
                  :name="p"
                  v-model.trim="user[p]"
                  :dense="true"
                />
              </div>
              <div class="col-8" v-else-if="p == 'status'">
                <q-checkbox v-model="user[p]" />
              </div>
              <div class="col-8" v-else>
                <q-input
                  outlined
                  v-model.trim="user[p]"
                  :name="p"
                  :dense="true"
                />
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn :disable="saveu" @click="updateuser(user)">Сохранить</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "users",
  components: {},
  data() {
    return {
      show: false,
      saveu: true,
      users: {},
      user: {
        id: 0,
        email: "",
        username: "",
        surname: "",
        password: "",
        cpassword: "",
        status: true,
        role: "manager"
      },
      roles: ["admin", "manager"]
    };
  },
  created() {},
  mounted() {
    this.getusers();
  },
  watch: {
    "user.cpassword"() {
      if (this.user.cpassword != this.user.password) {
        this.saveu = true;
      } else {
        this.saveu = false;
      }
    },
    "user.password"() {
      if (this.user.cpassword != this.user.password) {
        this.saveu = true;
      } else {
        this.saveu = false;
      }
    }
  },
  methods: {
    getusers() {
      this.$store.state.progress = true;
      this.$h.get("/settings/get-users").then(r => {
        this.users = r.data.data;
        this.$store.state.progress = false;
      });
    },
    setuser(user) {
      for (let u in this.user) {
        if (u == "status") {
          this.user[u] = user[u] > 0 ? true : false;
        } else {
          this.user[u] = user[u];
        }
      }
      this.show = true;
    },
    adduser(user) {
      this.user = {
        id: 0,
        email: "",
        username: "",
        surname: "",
        password: "",
        cpassword: "",
        status: true,
        role: "manager"
      };
      this.show = true;
    },
    updateuser(user) {
      for (let u in user) {
        if (u != "cpassword" && u != "password" && u != "status") {
          console.log(u);
          if (user[u].length < 3 && u != "id" && u != "status") {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: this.$t("user")[u] + " - слишком короткое или пустое",
              timeout: 2000
            });
            return;
          }
        }
      }
      this.$store.state.progress = true;
      this.$h.post("/settings/set-users", { u: user }).then(r => {
        if (r.data.error) {
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: r.data.message,
            timeout: 2000
          });
        } else {
          Notify.create({
            position: "top",
            color: "green",
            message: "Сохранено",
            timeout: 2000
          });
          this.show = false;
          this.users = r.data.data;
        }
        this.$store.state.progress = false;
      });
    }
  }
};
</script>

<style>
.item_form {
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>
