<template>
  <div>
    <div class="row" style="padding-bottom: 25px">
      <div class="col-1"></div>
      <div class="col-2">
        <q-input
          outlined
          label="Новый регион"
          v-model="region.name"
          :dense="true"
        />
      </div>
      <div class="col-1">
        <q-btn @click="addRegion">Добавить</q-btn>
      </div>
      <div class="col-1"></div>
      <div class="col-1"></div>
      <div class="col-2">
        <q-input
          label="Переименовать"
          outlined
          type="text"
          :name="setreg.region_id"
          v-model.number="setreg.name"
          :dense="true"
        />
      </div>
      <div class="col-4"></div>
    </div>
    <div>
      <q-splitter v-model="splitterModel">
        <template v-slot:before>
          <q-tabs v-model="tab" vertical class="text-teal">
            <q-tab
              v-for="r in regions"
              :name="r.region_id"
              :label="r.name"
              :key="r.region_id"
            />
          </q-tabs>
        </template>

        <template v-slot:after>
          <q-tab-panels
            v-model="tab"
            animated
            swipeable
            vertical
            transition-prev="jump-up"
            transition-next="jump-up"
          >
            <q-tab-panel
              v-for="r in regions"
              :name="r.region_id"
              :label="r.name"
              :key="r.region_id + Math.random()"
            >
              <div>
                <q-select
                  filled
                  v-model="regCity"
                  :options="cities"
                  label="Добавить город"
                  multiple
                  emit-value
                  map-options
                >
                  <template
                    v-slot:option="{
                      itemProps,
                      itemEvents,
                      opt,
                      selected,
                      toggleOption
                    }"
                  >
                    <q-item v-bind="itemProps" v-on="itemEvents">
                      <q-item-section>
                        <q-item-label v-html="opt.label"></q-item-label>
                      </q-item-section>
                      <q-item-section side>
                        <q-toggle
                          :value="selected"
                          @input="toggleOption(opt)"
                        />
                      </q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div>
                <q-select
                  filled
                  v-model="cityCompet"
                  :options="competitors"
                  label="Добавить конкурента"
                  multiple
                  emit-value
                  map-options
                >
                  <template
                    v-slot:option="{
                      itemProps,
                      itemEvents,
                      opt,
                      selected,
                      toggleOption
                    }"
                  >
                    <q-item v-bind="itemProps" v-on="itemEvents">
                      <q-item-section>
                        <q-item-label v-html="opt.label"></q-item-label>
                      </q-item-section>
                      <q-item-section side>
                        <q-toggle
                          :value="selected"
                          @input="toggleOption(opt)"
                        />
                      </q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
              <div style="display: flex;justify-content: end;padding-top: 20px">
                <q-btn @click="setRegion(setreg)"
                  >Обновить {{ setreg.name }}</q-btn
                >
                <q-btn color="red" @click="delRegion(setreg)"
                  >Удалить {{ setreg.name }}</q-btn
                >
              </div>
            </q-tab-panel>
          </q-tab-panels>
        </template>
      </q-splitter>
    </div>
  </div>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "cities",
  components: {},
  data() {
    return {
      splitterModel: 10,
      region: {
        name: "",
        region_id: 0
      },
      tab: "",
      setreg: {
        name: "",
        region_id: "0"
      },
      regions: {},
      regCity: [],
      cities: [],
      competitors: [],
      cityCompet: []
    };
  },
  created() {},
  mounted() {
    this.getRegions();
    this.getCities();
  },
  watch: {
    tab(val) {
      for (let rg in this.regions) {
        let r = this.regions[rg];
        if (val == r.region_id) {
          this.setreg = r;
          this.getRegInfo(this.setreg.region_id);
        }
      }
    },
    regCity() {
      this.getCompetitorsInCity(this.regCity);
    }
  },
  methods: {
    getRegInfo(reg) {
      this.$store.state.progress = true;
      this.regCity = [];
      this.cityCompet = [];
      this.$h.get(`/settings/get-region-info?reg=${reg}`).then(r => {
        const info = r.data.data;
        for (let inf in info) {
          let d = info[inf];
          if (this.regCity.indexOf(d.city_id) == -1) {
            this.regCity.push(d.city_id);
          }
          this.cityCompet.push(d.id);
        }
        this.$store.state.progress = false;
      });
    },
    getCompetitorsInCity(citId) {
      this.$store.state.progress = true;
      this.$h.get(`/settings/get-comp-by-cities?id=${citId}`).then(r => {
        const compet = r.data.data;
        for (let c in compet) {
          let add = true;
          let compt = {
            label: compet[c].competitor,
            value: compet[c].id
          };
          for (let cp in this.competitors) {
            if (this.competitors[cp].value == compt.value) {
              add = false;
            }
          }
          if (add) {
            this.competitors.push(compt);
          }
        }
        this.$store.state.progress = false;
      });
    },
    getRegions() {
      this.$store.state.progress = true;
      this.$h
        .get("/settings/get-regions")
        .then(r => {
          const reg = r.data.data;
          this.regions = reg;
          this.setreg = reg[0];
          this.$store.state.progress = false;
        })
        .then(() => {
          this.tab = this.setreg.region_id;
        });
    },
    getCities() {
      this.$store.state.progress = true;
      this.cities = [];
      this.$h.get("/settings/get-cities").then(r => {
        const cits = r.data.data;
        for (let c in cits) {
          this.cities.push({
            label: cits[c].name,
            value: cits[c].city_id
          });
        }
        this.$store.state.progress = false;
      });
    },
    addRegion() {
      if (this.region.name.length < 3) {
        Notify.create({
          position: "top",
          type: "errors",
          color: "red",
          message: "Название региона слишком короткое или пустое",
          timeout: 2000
        });
        return;
      }
      this.$store.state.progress = true;
      this.$h.post("/settings/set-region", { reg: this.region }).then(r => {
        if (r.data.error) {
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: r.data.message,
            timeout: 2000
          });
        } else {
          Notify.create({
            position: "top",
            color: "green",
            message: "Сохранено",
            timeout: 2000
          });
          this.regions = r.data.data;
        }
        this.$store.state.progress = false;
      });
    },
    setRegion(region) {
      if (region.name.length < 3) {
        Notify.create({
          position: "top",
          type: "errors",
          color: "red",
          message: "Название региона слишком короткое или пустое",
          timeout: 2000
        });
        return;
      }
      this.$store.state.progress = true;
      this.$h
        .post("/settings/set-region", {
          reg: region,
          info: {
            cities: this.regCity,
            compt: this.cityCompet
          }
        })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: r.data.message,
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
            this.regions = r.data.data;
          }
          this.$store.state.progress = false;
        });
    },
    delRegion(reg) {
      console.log(reg);
    }
  }
};
</script>

<style scoped></style>
