<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created: function() {
    this.$h.interceptors.response.use(undefined, function(err) {
      return new Promise(function(resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch("logout");
        }
        throw err;
      });
    });
  },
  watch: {
    "$route.name"(val) {
      document.title = "Lucky Track " + ("- " + this.$t("menu")[val] || "");
    }
  }
};
</script>
<style></style>
