var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row",staticStyle:{"padding-bottom":"25px"}},[_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-2"},[_c('q-input',{attrs:{"outlined":"","label":"Новый регион","dense":true},model:{value:(_vm.region.name),callback:function ($$v) {_vm.$set(_vm.region, "name", $$v)},expression:"region.name"}})],1),_c('div',{staticClass:"col-1"},[_c('q-btn',{on:{"click":_vm.addRegion}},[_vm._v("Добавить")])],1),_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-2"},[_c('q-input',{attrs:{"label":"Переименовать","outlined":"","type":"text","name":_vm.setreg.region_id,"dense":true},model:{value:(_vm.setreg.name),callback:function ($$v) {_vm.$set(_vm.setreg, "name", _vm._n($$v))},expression:"setreg.name"}})],1),_c('div',{staticClass:"col-4"})]),_c('div',[_c('q-splitter',{scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('q-tabs',{staticClass:"text-teal",attrs:{"vertical":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.regions),function(r){return _c('q-tab',{key:r.region_id,attrs:{"name":r.region_id,"label":r.name}})}),1)]},proxy:true},{key:"after",fn:function(){return [_c('q-tab-panels',{attrs:{"animated":"","swipeable":"","vertical":"","transition-prev":"jump-up","transition-next":"jump-up"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.regions),function(r){return _c('q-tab-panel',{key:r.region_id + Math.random(),attrs:{"name":r.region_id,"label":r.name}},[_c('div',[_c('q-select',{attrs:{"filled":"","options":_vm.cities,"label":"Добавить город","multiple":"","emit-value":"","map-options":""},scopedSlots:_vm._u([{key:"option",fn:function({
                    itemProps,
                    itemEvents,
                    opt,
                    selected,
                    toggleOption
                  }){return [_c('q-item',_vm._g(_vm._b({},'q-item',itemProps,false),itemEvents),[_c('q-item-section',[_c('q-item-label',{domProps:{"innerHTML":_vm._s(opt.label)}})],1),_c('q-item-section',{attrs:{"side":""}},[_c('q-toggle',{attrs:{"value":selected},on:{"input":function($event){return toggleOption(opt)}}})],1)],1)]}}],null,true),model:{value:(_vm.regCity),callback:function ($$v) {_vm.regCity=$$v},expression:"regCity"}})],1),_c('div',[_c('q-select',{attrs:{"filled":"","options":_vm.competitors,"label":"Добавить конкурента","multiple":"","emit-value":"","map-options":""},scopedSlots:_vm._u([{key:"option",fn:function({
                    itemProps,
                    itemEvents,
                    opt,
                    selected,
                    toggleOption
                  }){return [_c('q-item',_vm._g(_vm._b({},'q-item',itemProps,false),itemEvents),[_c('q-item-section',[_c('q-item-label',{domProps:{"innerHTML":_vm._s(opt.label)}})],1),_c('q-item-section',{attrs:{"side":""}},[_c('q-toggle',{attrs:{"value":selected},on:{"input":function($event){return toggleOption(opt)}}})],1)],1)]}}],null,true),model:{value:(_vm.cityCompet),callback:function ($$v) {_vm.cityCompet=$$v},expression:"cityCompet"}})],1),_c('div',{staticStyle:{"display":"flex","justify-content":"end","padding-top":"20px"}},[_c('q-btn',{on:{"click":function($event){return _vm.setRegion(_vm.setreg)}}},[_vm._v("Обновить "+_vm._s(_vm.setreg.name))]),_c('q-btn',{attrs:{"color":"red"},on:{"click":function($event){return _vm.delRegion(_vm.setreg)}}},[_vm._v("Удалить "+_vm._s(_vm.setreg.name))])],1)])}),1)]},proxy:true}]),model:{value:(_vm.splitterModel),callback:function ($$v) {_vm.splitterModel=$$v},expression:"splitterModel"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }