<template>
  <q-card style="min-height: 500px">
    <div style="padding-top: 25px;padding-bottom: 25px">
      <div class="row">
        <div class="col-1"></div>
        <div class="col-2">
          <q-input
            outlined
            label="Новый Алгоритм"
            v-model="algName"
            :dense="true"
          />
        </div>
        <div class="col-1"></div>
        <div class="col-1">
          <q-btn :disable="algName.length < 3" @click="addAlgorithm()"
            >Добавить
          </q-btn>
        </div>
        <div class="col-1"></div>
        <div class="col-2"></div>
        <div class="col-4"></div>
      </div>
      <div class="row">
        <div class="col-12">
          <q-btn
            style="margin: 15px"
            color="blue"
            v-if="updateAllAlg"
            @click="updateAll"
            >Обновить все</q-btn
          >
        </div>
        <div class="col-1" style="text-align: center">
          <b>Приоритет</b>
        </div>
        <div class="col-1" style="text-align: center"><b>Вкл/Выкл</b></div>
        <div class="col-4" style="text-align: center">
          <b>Название</b>
        </div>
        <div class="col-2" style="text-align: center"></div>
        <div class="col-1"></div>
        <div class="col-1"></div>
        <div class="col-1"></div>
      </div>
      <div
        style="padding-top: 15px"
        class="row"
        v-for="alg in algorithms"
        :key="alg.adj_alg_id + 'a'"
      >
        <div class="col-1" style="text-align: center">
          <select
            v-model="alg.priority"
            @change="algChange(alg.priority - 1, alg)"
          >
            <option v-for="p in priorities" :key="p + 'p'">{{ p }} </option>
          </select>
        </div>
        <div class="col-1" style="text-align: center">
          <input v-model="alg.alg_enable" type="checkbox" />
        </div>
        <div class="col-4" style="text-align: center">
          <b><input style="width: 100%" v-model="alg.name"/></b>
        </div>
        <div class="col-2" style="text-align: center"></div>
        <div class="col-1" style="margin: 0 10px">
          <q-btn @click="$router.push(`/adjustments/edit?id=${alg.adj_alg_id}`)"
            >Настроить
          </q-btn>
        </div>
        <div class="col-1" style="margin: 0 10px">
          <q-btn color="blue" @click="updateAlg(alg)">Сохранить</q-btn>
        </div>
        <div class="col-1" style="margin: 0 10px">
          <q-btn color="red" @click="deleteDial(alg)">Удалить</q-btn>
        </div>
      </div>
    </div>
    <q-dialog
      v-model="delConfirm"
      v-if="delAlg"
      persistent
      style="min-width: 300px"
    >
      <q-card>
        <q-card-section class="row items-center">
          <span style="text-align: center"
            >Уверены, что хотите удалить <br /><b>{{ delAlg.name }}</b></span
          >
        </q-card-section>

        <q-card-actions align="center">
          <q-btn
            flat
            label="Отмена"
            @click="delConfirm = false"
            color="primary"
            v-close-popup
          />
          <q-btn
            flat
            label="Удалить"
            @click="deleteAlg"
            color="red"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "algorithms",
  components: {},
  data() {
    return {
      delConfirm: false,
      priorities: [],
      algName: "",
      algorithms: [],
      delAlg: null,
      index: 0,
      updateAllAlg: false
    };
  },
  created() {},
  mounted() {
    this.getAlgorithms();
  },
  watch: {
    index(val) {
      this.$store.state.progress = val == 0 ? false : true;
    }
  },
  methods: {
    deleteDial(alg) {
      this.delConfirm = true;
      this.delAlg = alg;
    },
    deleteAlg() {
      this.index++;
      this.priorities = [];
      this.$h
        .post(`/adjustments-algorithms/del-alg`, {
          alg: this.delAlg
        })
        .then(r => {
          this.algorithms = r.data.data;
          for (let p in this.algorithms) {
            this.priorities.push(this.algorithms[p].priority);
          }
          this.index--;
        });
    },
    getAlgorithms() {
      this.index++;
      this.priorities = [];
      this.$h.get(`/adjustments-algorithms/get-list`).then(r => {
        this.algorithms = r.data.data;
        this.algorithms.forEach(alg => {
          this.$watch(() => [alg.alg_enable, alg.adj_alg_id], this.enableAlg, {
            deep: true
          });
        });
        for (let p in this.algorithms) {
          this.priorities.push(this.algorithms[p].priority);
        }

        this.index--;
      });
    },
    enableAlg(arg) {
      this.index++;
      this.$h
        .post("/adjustments-algorithms/enable-alg", {
          alg: arg[1],
          enable: arg[0]
        })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: r.data.message,
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
          }
          this.index--;
        });
    },
    algChange(priority, item) {
      let index = this.algorithms.indexOf(item);
      this.algorithms.splice(index, 1);
      this.algorithms.splice(priority, 0, item);
      for (let i in this.algorithms) {
        this.algorithms[i].priority = parseInt(i) + 1;
      }
      this.updateAllAlg = true;
    },

    addAlgorithm() {
      this.index++;
      this.priorities = [];
      this.$h
        .post("/adjustments-algorithms/add", { name: this.algName })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: r.data.message,
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
            this.algorithms = r.data.data;
            for (let p in this.algorithms) {
              this.priorities.push(this.algorithms[p].priority);
            }
          }
          this.index--;
        });
    },
    updateAlg(alg) {
      this.index++;
      this.priorities = [];
      this.$h.post("/adjustments-algorithms/update", { alg: alg }).then(r => {
        if (r.data.error) {
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: r.data.message,
            timeout: 2000
          });
        } else {
          Notify.create({
            position: "top",
            color: "green",
            message: "Сохранено",
            timeout: 2000
          });
          this.algorithms = r.data.data;
          for (let p in this.algorithms) {
            this.priorities.push(this.algorithms[p].priority);
          }
        }
        this.index--;
      });
    },
    updateAll(alg) {
      this.index++;
      this.priorities = [];
      this.$h
        .post("/adjustments-algorithms/update-all", { algs: this.algorithms })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: r.data.message,
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
            this.algorithms = r.data.data;
            for (let p in this.algorithms) {
              this.priorities.push(this.algorithms[p].priority);
            }
          }
          this.updateAllAlg = false;
          this.index--;
        });
    }
  }
};
</script>
