import axios from "axios";
import login from "@/pages/login";

const token = localStorage.getItem("token");
const locale = "ru";

var baseUrl = `http://${location.hostname}:8443`;
const api = axios.create({
  baseURL: baseUrl,
  headers: {
    Ltoken: "token " + token,
    Locale: locale
  }
});

api.interceptors.request.use(
  async config => {
    config.headers.common["Ltoken"] = "token " + token;
    config.headers.common["Locale"] = locale;
    return config;
  },
  async error => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  async response => {
    const d = response.data;
    if (d.status) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.href = "/login";
    }
    return response;
  },
  async error => {
    return error;
  }
);
export default api;
