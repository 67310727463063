var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-page',{staticClass:"ub-page"},[_c('div',{staticClass:"q-gutter-y-md",staticStyle:{"max-width":"100%"}},[_c('div',{staticClass:"q-gutter-md",staticStyle:{"text-align":"center"}},[_c('h4',{staticStyle:{"text-align":"center","font-weight":"bold"}},[_vm._v(" Алгоритм \""+_vm._s(_vm.alg.name)+" - "+_vm._s(_vm.alg.price)+"\" ")])]),_c('q-card',{staticStyle:{"min-height":"650px"}},[_c('div',{staticClass:"q-pa-md example-column-equal-width"},[_c('div',{staticClass:"row",staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"col-1"},[_vm._v("Все кроме")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-1",staticStyle:{"text-align":"center"}},[_c('q-checkbox',{model:{value:(_vm.alg.cats_add),callback:function ($$v) {_vm.$set(_vm.alg, "cats_add", $$v)},expression:"alg.cats_add"}})],1),_c('div',{staticClass:"col-6"},[_c('q-select',{attrs:{"options":_vm.categories,"label":"Добавить категорию","filled":"","multiple":"","emit-value":"","map-options":"","dense":"","use-chips":"","input-debounce":"0"},scopedSlots:_vm._u([{key:"option",fn:function({
                  itemProps,
                  itemEvents,
                  opt,
                  selected,
                  toggleOption
                }){return [_c('q-item',_vm._g(_vm._b({},'q-item',itemProps,false),itemEvents),[_c('q-item-section',[_c('q-item-label',{domProps:{"innerHTML":_vm._s(opt.label)}})],1),_c('q-item-section',{attrs:{"side":""}},[_c('q-toggle',{attrs:{"value":selected},on:{"input":function($event){return toggleOption(opt)}}})],1)],1)]}}]),model:{value:(_vm.algCats),callback:function ($$v) {_vm.algCats=$$v},expression:"algCats"}})],1),_c('div',{staticClass:"col-3"},[_c('q-select',{staticStyle:{"width":"250px"},attrs:{"filled":"","use-input":"","dense":"","hide-selected":"","input-debounce":"0","label":"Найти категорию","options":_vm.optionsCat},on:{"filter":_vm.filterCat},scopedSlots:_vm._u([{key:"no-option",fn:function(){return [_c('q-item',[_c('q-item-section',{staticClass:"text-grey"},[_vm._v(" No results ")])],1)]},proxy:true}]),model:{value:(_vm.searchCat),callback:function ($$v) {_vm.searchCat=$$v},expression:"searchCat"}})],1),_c('div',{staticClass:"col-2"},[(_vm.algCats.length < _vm.categories.length)?_c('q-btn',{on:{"click":_vm.selectAllCats}},[_vm._v("Выбрать все")]):_c('q-btn',{on:{"click":function($event){_vm.algCats = []}}},[_vm._v("Убрать все")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-1",staticStyle:{"text-align":"center"}},[_c('q-checkbox',{model:{value:(_vm.alg.city_add),callback:function ($$v) {_vm.$set(_vm.alg, "city_add", $$v)},expression:"alg.city_add"}})],1),_c('div',{staticClass:"col-6"},[_c('q-select',{attrs:{"filled":"","options":_vm.cities,"label":"Добавить город","multiple":"","emit-value":"","map-options":"","dense":"","use-chips":"","input-debounce":"0"},scopedSlots:_vm._u([{key:"option",fn:function({
                  itemProps,
                  itemEvents,
                  opt,
                  selected,
                  toggleOption
                }){return [_c('q-item',_vm._g(_vm._b({},'q-item',itemProps,false),itemEvents),[_c('q-item-section',[_c('q-item-label',{domProps:{"innerHTML":_vm._s(opt.label)}})],1),_c('q-item-section',{attrs:{"side":""}},[_c('q-toggle',{attrs:{"value":selected},on:{"input":function($event){return toggleOption(opt)}}})],1)],1)]}}]),model:{value:(_vm.regCity),callback:function ($$v) {_vm.regCity=$$v},expression:"regCity"}})],1),_c('div',{staticClass:"col-3"},[_c('q-select',{staticStyle:{"width":"250px"},attrs:{"filled":"","use-input":"","dense":"","hide-selected":"","input-debounce":"0","label":"Найти город","options":_vm.optionsCity},on:{"filter":_vm.filterCity},scopedSlots:_vm._u([{key:"no-option",fn:function(){return [_c('q-item',[_c('q-item-section',{staticClass:"text-grey"},[_vm._v(" No results ")])],1)]},proxy:true}]),model:{value:(_vm.searchCity),callback:function ($$v) {_vm.searchCity=$$v},expression:"searchCity"}})],1),_c('div',{staticClass:"col-2"},[(_vm.regCity.length < _vm.cities.length)?_c('q-btn',{on:{"click":_vm.selectAllCities}},[_vm._v("Выбрать все")]):_c('q-btn',{on:{"click":function($event){_vm.regCity = []}}},[_vm._v("Убрать все")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.index == 0)?_c('formula',{attrs:{"formulas":_vm.formula,"arr-types":_vm.items,"statuses":_vm.statuses,"projects":_vm.projects,"priceName":_vm.alg.price},on:{"update:formulas":function($event){_vm.formula=$event},"formulaUpdate":_vm.upFormula}}):_vm._e()],1)]),_c('div',{staticClass:"row",staticStyle:{"text-align":"center","padding":"15px 0","display":"block"}},[_c('q-btn',{attrs:{"color":"blue","align":"right"},on:{"click":_vm.saveAlg}},[_vm._v("Сохранить")])],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }