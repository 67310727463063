import { render, staticRenderFns } from "./analysis.vue?vue&type=template&id=5494d2d2&scoped=true&"
import script from "./analysis.vue?vue&type=script&lang=js&"
export * from "./analysis.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5494d2d2",
  null
  
)

export default component.exports