var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-page',{staticClass:"ub-page"},[_c('div',{staticClass:"q-gutter-y-md",staticStyle:{"max-width":"100%"}},[_c('div',{staticClass:"q-gutter-md",staticStyle:{"text-align":"center"}},[_c('h4',{staticStyle:{"text-align":"center","font-weight":"bold"}},[_vm._v(" Конкурент "+_vm._s((_vm.comp.name ? _vm.comp.name + " " : "") + _vm.comp.competitor_id)+" - алгоритм \""+_vm._s(_vm.alg.name)+"\" ")])]),_c('q-card',{staticStyle:{"min-height":"650px"}},[_c('div',{staticClass:"q-pa-md example-column-equal-width"},[_c('div',{staticClass:"row",staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"col-1"},[_vm._v("Все кроме")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-1",staticStyle:{"text-align":"center"}},[_c('q-checkbox',{model:{value:(_vm.alg.cats_add),callback:function ($$v) {_vm.$set(_vm.alg, "cats_add", $$v)},expression:"alg.cats_add"}})],1),_c('div',{staticClass:"col-6"},[_c('q-select',{attrs:{"filled":"","options":_vm.categories,"label":"Добавить категорию","multiple":"","emit-value":"","map-options":"","dense":"","use-chips":"","input-debounce":"0"},scopedSlots:_vm._u([{key:"option",fn:function({
                  itemProps,
                  itemEvents,
                  opt,
                  selected,
                  toggleOption
                }){return [_c('q-item',_vm._g(_vm._b({},'q-item',itemProps,false),itemEvents),[_c('q-item-section',[_c('q-item-label',{domProps:{"innerHTML":_vm._s(opt.label)}})],1),_c('q-item-section',{attrs:{"side":""}},[_c('q-toggle',{attrs:{"value":selected},on:{"input":function($event){return toggleOption(opt)}}})],1)],1)]}}]),model:{value:(_vm.compCats),callback:function ($$v) {_vm.compCats=$$v},expression:"compCats"}})],1),_c('div',{staticClass:"col-3"},[_c('q-select',{staticStyle:{"width":"250px"},attrs:{"filled":"","use-input":"","dense":"","hide-selected":"","input-debounce":"0","label":"Найти категорию","options":_vm.optionsCat},on:{"filter":_vm.filterCat},scopedSlots:_vm._u([{key:"no-option",fn:function(){return [_c('q-item',[_c('q-item-section',{staticClass:"text-grey"},[_vm._v(" No results ")])],1)]},proxy:true}]),model:{value:(_vm.searchCat),callback:function ($$v) {_vm.searchCat=$$v},expression:"searchCat"}})],1),_c('div',{staticClass:"col-2"},[(_vm.compCats.length != _vm.categories.length)?_c('q-btn',{on:{"click":_vm.selectAllCats}},[_vm._v("Выбрать все ")]):_c('q-btn',{on:{"click":function($event){_vm.compCats = []}}},[_vm._v("Убрать все")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-1",staticStyle:{"text-align":"center"}},[_c('q-checkbox',{model:{value:(_vm.alg.brand_add),callback:function ($$v) {_vm.$set(_vm.alg, "brand_add", $$v)},expression:"alg.brand_add"}})],1),_c('div',{staticClass:"col-6"},[_c('q-select',{attrs:{"filled":"","options":_vm.brands,"label":"Добавить Бренд","multiple":"","emit-value":"","map-options":"","dense":"","use-chips":"","input-debounce":"0"},scopedSlots:_vm._u([{key:"option",fn:function({
                  itemProps,
                  itemEvents,
                  opt,
                  selected,
                  toggleOption
                }){return [_c('q-item',_vm._g(_vm._b({},'q-item',itemProps,false),itemEvents),[_c('q-item-section',[_c('q-item-label',{domProps:{"innerHTML":_vm._s(opt.label)}})],1),_c('q-item-section',{attrs:{"side":""}},[_c('q-toggle',{attrs:{"value":selected},on:{"input":function($event){return toggleOption(opt)}}})],1)],1)]}}]),model:{value:(_vm.compBrands),callback:function ($$v) {_vm.compBrands=$$v},expression:"compBrands"}})],1),_c('div',{staticClass:"col-3"},[_c('q-select',{staticStyle:{"width":"250px"},attrs:{"filled":"","use-input":"","dense":"","hide-selected":"","input-debounce":"0","label":"Найти бренд","options":_vm.optionsBrands},on:{"filter":_vm.filterCity},scopedSlots:_vm._u([{key:"no-option",fn:function(){return [_c('q-item',[_c('q-item-section',{staticClass:"text-grey"},[_vm._v(" No results ")])],1)]},proxy:true}]),model:{value:(_vm.searchBrand),callback:function ($$v) {_vm.searchBrand=$$v},expression:"searchBrand"}})],1),_c('div',{staticClass:"col-2"},[(_vm.compBrands.length != _vm.brands.length)?_c('q-btn',{on:{"click":_vm.selectAllBrands}},[_vm._v("Выбрать все ")]):_c('q-btn',{on:{"click":function($event){_vm.compBrands = []}}},[_vm._v("Убрать все")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-1",staticStyle:{"text-align":"center"}},[_c('q-checkbox',{model:{value:(_vm.alg.price_add),callback:function ($$v) {_vm.$set(_vm.alg, "price_add", $$v)},expression:"alg.price_add"}})],1),_c('div',{staticClass:"col-6"},[_c('q-select',{attrs:{"filled":"","options":_vm.prices,"label":"Добавить цену","multiple":"","emit-value":"","map-options":"","dense":"","use-chips":"","input-debounce":"0"},scopedSlots:_vm._u([{key:"option",fn:function({
                  itemProps,
                  itemEvents,
                  opt,
                  selected,
                  toggleOption
                }){return [_c('q-item',_vm._g(_vm._b({},'q-item',itemProps,false),itemEvents),[_c('q-item-section',[_c('q-item-label',{domProps:{"innerHTML":_vm._s(opt.label)}})],1),_c('q-item-section',{attrs:{"side":""}},[_c('q-toggle',{attrs:{"value":selected},on:{"input":function($event){return toggleOption(opt)}}})],1)],1)]}}]),model:{value:(_vm.compPrices),callback:function ($$v) {_vm.compPrices=$$v},expression:"compPrices"}})],1),_c('div',{staticClass:"col-3"},[_c('q-select',{staticStyle:{"width":"250px"},attrs:{"filled":"","use-input":"","dense":"","hide-selected":"","input-debounce":"0","label":"Найти цену","options":_vm.optionsPrices},on:{"filter":_vm.filterPrices},scopedSlots:_vm._u([{key:"no-option",fn:function(){return [_c('q-item',[_c('q-item-section',{staticClass:"text-grey"},[_vm._v(" No results ")])],1)]},proxy:true}]),model:{value:(_vm.searchPrice),callback:function ($$v) {_vm.searchPrice=$$v},expression:"searchPrice"}})],1),_c('div',{staticClass:"col-2"},[(_vm.compPrices.length < _vm.prices.length)?_c('q-btn',{on:{"click":_vm.selectAllPrices}},[_vm._v("Выбрать все ")]):_c('q-btn',{on:{"click":function($event){_vm.compPrices = []}}},[_vm._v("Убрать все")])],1)]),(_vm.index == 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('formula',{attrs:{"formulas":_vm.formula,"arr-types":_vm.items},on:{"update:formulas":function($event){_vm.formula=$event},"formulaUpdate":_vm.upFormula}})],1)]):_vm._e(),_c('div',{staticClass:"row",staticStyle:{"text-align":"center","padding":"15px 0","display":"block"}},[_c('q-btn',{attrs:{"color":"blue","align":"right"},on:{"click":_vm.saveComp}},[_vm._v("Сохранить")])],1)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }