<template>
  <q-page class="ub-page">
    <div class="q-gutter-y-md" style="max-width: 100%;">
      <vue-excel-xlsx
        hidden="true"
        class="f_type_b_exp"
        id="btn_xlsx_all"
        :data="xlDataAll"
        :columns="xlxColumnAll"
        :file-name="'Просчет_' + pDate"
        :file-type="'xlsx'"
        sheet-name="exel"
      >
        Скачать
      </vue-excel-xlsx>
      <q-card>
        <q-tabs
          v-model="tab"
          dense
          class="text-grey"
          active-color="primary"
          indicator-color="primary"
          align="justify"
          narrow-indicator
        >
          <q-tab
            :name="parseInt(p.price_id)"
            :label="p.name"
            v-for="p in tabs"
            :key="p.price_id"
          />
        </q-tabs>

        <q-separator />
        <q-tab-panels v-model="tab" animated style="min-height: 640px">
          <q-tab-panel
            v-for="p in tabs"
            :name="parseInt(p.price_id)"
            :key="p.price_id + 't'"
          >
            <div class="row">
              <div v-if="reload == false" class="col-3">
                <h6 style="margin: 0 !important;">
                  Дата парсинга <b>{{ pDate }}</b>
                </h6>
                <br />
              </div>
              <div v-if="reload == false" class="col-2"></div>
              <div v-if="reload == false" class="col-2">
                <q-btn @click="algReload" class="f_type_b_exp">
                  <div style="padding: 0 5px">Пересчитать всё</div>
                </q-btn>
              </div>
              <div v-if="reload == false" class="col-5">
                <q-checkbox
                  left-label
                  v-model="collapse"
                  size="md"
                  label="Схолпнуть по SKU"
                />
                <q-btn size="xl" @click="getFileAll" class="f_type_b_exp">
                  <div style="padding: 0 5px">Скачать пересчет</div>
                </q-btn>
                <q-btn size="xl" @click="getFile" class="f_type_b_exp">
                  <div style="padding: 0 5px">Скачать {{ price_n }}</div>
                </q-btn>
              </div>
            </div>
            <div
              v-if="reload"
              style="margin:auto; height: 500px;display: flex;"
            >
              <div style="display: block;margin:auto;text-align: center">
                <h6>
                  Идет пересчет алгоритмов... {{ reloadR }}/{{ reloadAll }}
                </h6>
                <br />
                <q-spinner-hourglass color="primary" size="xl" />
              </div>
            </div>
            <tab
              v-else
              :fName="pDate + '_' + price_n"
              :collapse="collapse"
              :tab="tab"
            />
          </q-tab-panel>
        </q-tab-panels>
      </q-card>
    </div>
  </q-page>
</template>

<script>
import { Notify } from "quasar";
import Tab from "@/components/parser/tab.vue";

export default {
  name: "parser",
  components: { Tab },
  data() {
    return {
      xlxColumnAll: [],
      xlDataAll: [],
      collapse: false,
      price_n: "",
      pDate: "",
      sArt: "",
      algorithms: [],
      algorithm: 0,
      tab: 0,
      tabs: [],
      index: 0,
      reload: false,
      reloadAll: 0,
      reloadR: 0,
      getStat: false,
      refreshIntervalId: null
    };
  },
  created() {
    if (this.$route.query.tab) {
      this.tab = parseInt(this.$route.query.tab);
    }
    this.getPrices();
  },
  mounted() {
    if (this.reload == true && this.getStat == false) {
      this.refreshIntervalId = setInterval(() => {
        this.getReload();
      }, 3000);
    } else {
      clearInterval(this.refreshIntervalId);
      this.getStat = false;
    }
  },
  watch: {
    reload(val) {
      if (val == true || this.getStat == false) {
        this.refreshIntervalId = setInterval(() => {
          this.getReload();
        }, 3000);
      } else {
        clearInterval(this.refreshIntervalId);
        this.getStat = false;
      }
    },
    tab(val) {
      if (this.tabs[val]) {
        this.price_n = this.tabs[val - 1].name;
      }
      this.$router.push({
        path: this.$route.path,
        query: {
          tab: val
        }
      });
    },
    index(val) {
      this.$store.state.progress = val == 0 ? false : true;
    }
  },
  methods: {
    getPrices() {
      this.index++;
      this.$h.get("/algorithms/get-prices").then(r => {
        this.tabs = r.data.data.tabs;
        this.pDate = r.data.data.pdate;
        if (this.tab == 0 || this.tabs.length) {
          this.tab = parseInt(this.tabs[0].price_id);
          this.price_n = this.tabs[this.tab].name;
        }
        this.index--;
      });
    },
    algReload() {
      this.index++;
      this.$h.get(`/result/reload-price?prId=${this.tab}`).then(r => {
        this.reload = true;
        this.index--;
      });
    },
    getReload() {
      this.getStat = true;
      this.$h.get(`/result/reload-price-stat?prId=${this.tab}`).then(r => {
        this.reloadAll = parseInt(r.data.data.all);
        this.reloadR = parseInt(r.data.data.r);
        if (this.reloadR == 0) {
          this.reload = false;
          this.getStat = false;
        }
      });
    },

    getFileAll() {
      this.index++;
      this.xlxColumnAll = [];
      this.$h
        .get(`/result/get-all-sku?all=${this.collapse}`)
        .then(r => {
          let col = r.data.data.column;
          for (let c in col) {
            this.xlxColumnAll.push({
              field: c,
              label: col[c]
            });
          }
          this.xlDataAll = r.data.data.prods;
          console.log(this.xlDataAll);
        })
        .then(() => {
          setTimeout(() => {
            let link = document.getElementById("btn_xlsx_all");
            link.click();
            this.index = 0;
          }, 3000);
        });
    },
    getFile() {
      this.index++;
      setTimeout(() => {
        let link = document.getElementById("btn_xlsx");
        link.click();
        this.index = 0;
      }, 5000);
    }
  }
};
</script>

<style></style>
