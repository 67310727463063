<template>
  <div>
    <div class="row" style="padding: 5px 0;text-align: center">
      <div class="col-1"></div>
      <div class="col-3">
        <q-input size="sm" v-model="name" label="Название" />
      </div>
      <div class="col-3">
        <q-select
          style="margin: 5px"
          filled
          v-model="item"
          :options="schema"
          label="Поле из парсера"
          emit-value
          stack-label
          map-options
        />
      </div>
      <div class="col-2">
        <q-btn size="xl" style="font-size: 24px;margin: 5px;" @click="addItem"
          >Добавить
        </q-btn>
      </div>
    </div>
    <div v-for="c in items" :key="c.id" class="row">
      <div class="col-1"></div>
      <div class="col-3">
        <q-input size="sm" v-model="c.name" label="Название" />
      </div>
      <div class="col-3">
        <div
          style="height: 60px;
    display: flex;
    justify-content: center;"
        >
          <span style="margin: auto">{{ c.parces_column }}</span>
        </div>
      </div>
      <div class="col-2" style="text-align: center">
        <q-btn
          size="xl"
          style="font-size: 24px;margin: 5px;"
          @click="deleteItem(c)"
          >Удалить
        </q-btn>
      </div>
    </div>
    <div
      class="row"
      style="text-align: center;align-items: center;padding-top: 25px"
    >
      <q-btn
        size="xl"
        color="blue"
        style="font-size: 24px;margin: 5px auto;"
        @click="updateItems"
        >Сохранить
      </q-btn>
    </div>
  </div>
</template>

<script>
import { Notify } from "quasar";

export default {
  name: "parser",
  components: {},
  data() {
    return {
      item: "",
      name: "",
      schema: [],
      items: []
    };
  },
  created() {},
  mounted() {
    this.getItems();
  },
  watch: {},
  methods: {
    addItem() {
      this.items.push({
        name: this.name,
        parces_column: this.item,
        item: null
      });
      this.item = "";
      this.name = "";
    },
    getItems() {
      this.$store.state.progress = true;
      this.$h.get("/parser/get-tables-items").then(r => {
        this.items = r.data.data.items;
        this.schema = r.data.data.schema;
        this.$store.state.progress = false;
      });
    },
    updateItems() {
      this.$store.state.progress = true;
      this.$h
        .post("/parser/set-tables-items", { items: this.items })
        .then(r => {
          if (r.data.error) {
            Notify.create({
              position: "top",
              type: "errors",
              color: "red",
              message: "Ошибка",
              timeout: 2000
            });
          } else {
            Notify.create({
              position: "top",
              color: "green",
              message: "Сохранено",
              timeout: 2000
            });
            this.items = r.data.data.items;
            this.schema = r.data.data.schema;
          }
          this.$store.state.progress = false;
        });
    },
    deleteItem(item) {
      this.$store.state.progress = true;
      this.$h.post("/parser/del-tables-items", { item: item }).then(r => {
        if (r.data.error) {
          Notify.create({
            position: "top",
            type: "errors",
            color: "red",
            message: "Ошибка",
            timeout: 2000
          });
        } else {
          Notify.create({
            position: "top",
            color: "green",
            message: "Сохранено",
            timeout: 2000
          });
          this.items = r.data.data.items;
          this.schema = r.data.data.schema;
        }
        this.$store.state.progress = false;
      });
    }
  }
};
</script>

<style scoped></style>
