<template>
  <q-page class="ub-page">
    <div class="bvm_border rev_graf">
      <div class="row">
        <div class="col-12">
          <div class="page_faq">
            <b>{{ $t("keyFaq").tit }}</b>
            <br /><br /><br />
            <p>1. {{ $t("keyFaq").p1 }}</p>
            <img style="width: 100%" :src="`/images/faq/1${loc}.png`" />
            <br /><br /><br />
            <p>2. {{ $t("keyFaq").p2 }}</p>
            <img style="width: 100%" :src="`/images/faq/2${loc}.png`" />
            <br /><br /><br />
            <p>3. {{ $t("keyFaq").p3 }}</p>
            <img style="width: 100%" :src="`/images/faq/3${loc}.png`" />
            <br /><br /><br />
            <p>4. {{ $t("keyFaq").p4 }}</p>
            <img style="width: 100%" :src="`/images/faq/4${loc}.png`" />
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
export default {
  name: "faq",
  data() {
    return {
      loc: "ru"
    };
  },
  mounted() {},
  created() {
    this.loc = localStorage.getItem("locale");
  },
  watch: {},
  methods: {}
};
</script>

<style scoped>
.page_faq {
  margin: 30px;
}
.page_faq b,
.page_faq p {
  font-size: 16px;
}
</style>
