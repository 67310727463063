import Vue from "vue";
import Router from "vue-router";
import MainLayout from "./layouts/mainlayout.vue";
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};
import Login from "@/pages/login.vue";

import Error_p from "@/pages/error_p";

import Faq from "@/pages/faq.vue";
import Main from "@/pages/main.vue";
import Settings from "@/pages/settings.vue";
import Products from "@/pages/products.vue";
import Parser from "@/pages/parser.vue";
import Competitors from "@/pages/competitors.vue";
import Algorithms from "@/pages/algorithms.vue";
import Edit_algorithm from "@/components/prices/edit_algorithm.vue";
import Slave_edit_algorithm from "@/components/slave_prices/edit_algorithm.vue";
import Adjustments_edit_algorithm from "@/components/adjustments/edit_algorithm.vue";
import Edit_competitors from "@/components/settings/competitor/competitors.vue";
import Comp_algorithm from "@/components/settings/competitor/algorithms.vue";
import Ranges from "@/pages/ranges.vue";
import Coeff from "@/components/settings/competitor/coeff.vue";
import Slave_algorithms from "@/pages/slave_algorithms.vue";
import Slave_parser from "@/pages/slave_parser.vue";
import Result from "@/pages/result.vue";
import Reports from "@/pages/reports.vue";
import Adjustments from "@/pages/adjustments.vue";
import Analysis from "@/pages/analysis.vue";
import Mistakes from "@/pages/mistakes.vue";
import No_comp from "@/pages/no_comp.vue";

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: MainLayout,
      children: [
        {
          path: "/",
          name: "main_page",
          component: Main
        },
        {
          path: "/parser_result",
          name: "parser_result",
          component: Parser
        },
        {
          path: "/adjustments",
          name: "adjustments",
          component: Adjustments
        },
        {
          path: "/analysis",
          name: "analysis",
          component: Analysis
        },
        {
          path: "/mistakes",
          name: "mistakes",
          component: Mistakes
        },
        {
          path: "/no-comp",
          name: "no_comp",
          component: No_comp
        },
        {
          path: "/slave_result",
          name: "slave_result",
          component: Slave_parser
        },
        {
          path: "/competitors",
          name: "competitors",
          props: route => ({ query: route.query.current }),
          component: Competitors
        },
        {
          path: "/algorithms",
          name: "algorithms",
          component: Algorithms
        },
        {
          path: "/slave_algorithms",
          name: "slave_algorithms",
          component: Slave_algorithms
        },
        {
          path: "/ranges",
          name: "ranges",
          component: Ranges
        },
        {
          path: "/result",
          name: "result",
          component: Result
        },
        {
          path: "/reports",
          name: "reports",
          component: Reports
        },
        {
          path: "/algorithm/edit",
          name: "alg_edit",
          component: Edit_algorithm
        },
        {
          path: "/slave_algorithm/edit",
          name: "slave_alg_edit",
          component: Slave_edit_algorithm
        },
        {
          path: "/adjustments/edit",
          name: "adjustments_edit",
          component: Adjustments_edit_algorithm
        },
        {
          path: "/competitor/algorithms/edit",
          name: "edit_competitors_alg",
          component: Edit_competitors
        },
        {
          path: "/competitor/coeff",
          name: "coeff_bramd",
          component: Coeff
        },
        {
          path: "/competitor/algorithms",
          name: "edit_competitors",
          component: Comp_algorithm
        },
        {
          path: "/settings",
          name: "settings",
          component: Settings
        },
        {
          path: "/logout",
          name: "logout"
        }
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/login",
      component: Login
    },
    {
      path: "*",
      name: "error",
      component: Error_p
    }
    ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem("token") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath }
      });
    } else {
      let user = JSON.parse(localStorage.getItem("user"));
      if (to.matched.some(rec => rec.meta.is_admin)) {
        if (user.role == "admin") {
          next();
        } else {
          next({ name: "showcase" });
        }
      } else {
        next();
      }
      if (to.matched.some(recs => recs.meta.level)) {
        if (user.level >= to.meta.level) {
          next();
        } else {
          next({ name: "showcase" });
        }
      }
    }
  } else {
    next();
  }
});

export default router;
